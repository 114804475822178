.playQueueDropupContainer {
    @apply w-[300px] max-h-[400px] bg-[#242424] flex flex-col pt-4 px-2 absolute left-0 bottom-[4.6rem];
    @apply rounded-t-[1.5rem];
    z-index: 10000;
  }
  
  .playQueueDropupContainer .playQueueTitle {
    @apply flex justify-center items-center relative;
  }
  
  .playQueueDropupContainer .playQueueTitle span {
    @apply text-[1.3rem] text-white font-semibold mt-6;
  }
  
  .playQueueDropupContainer .playQueueTitle i {
    @apply text-2xl text-white absolute right-0 cursor-pointer;
  }
  
  .playQueueDropupContainer .savePlaylistTitle {
    @apply  text-sm text-gray-300 text-center mt-6;
  }
  
  .playQueueDropupContainer .playlistNameInput {
    @apply flex justify-start items-center text-white text-base rounded-full h-11 min-h-[2.75rem] px-10 py-1 bg-[#181818] mt-8 mx-4;
    @apply focus:outline-none;
  }
  
  .playQueueDropupContainer .buttonContainer {
    @apply grid grid-cols-2 gap-4 my-1 mx-4;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
      width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
  }
  
  .playQueueDropupContainer .buttonContainer div {
    @apply flex justify-center items-center gap-4 rounded-full bg-[#181818] px-3 py-3 cursor-pointer;
  }
  
  .playQueueDropupContainer .buttonContainer span {
    @apply text-sm text-white;
  }
  
  .playQueueDropupContainer .buttonContainer i {
    @apply text-base text-white;
  }
  
  /* Playlist songs */
  .playQueueDropupContainer .playQueueSongsContainer {
    @apply flex flex-col overflow-y-scroll gap-1 mt-2;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
      /* Hide playQueueSongsContainer scrollbar on Firefox due to limited options to set its width */
      scrollbar-width: none;
  }
  }

  .playQueueDropupContainer .playQueueSongsContainer::-webkit-scrollbar {
    width: 2px !important;
  }
  
  .playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem {
    @apply flex justify-between items-center gap-6 py-2;
    border-bottom: 1px solid #1c1c1c;
  }
  
  .playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songLeftSection {
    @apply flex justify-between items-center gap-4;
  }
  
  .playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songLeftSection .dragSongIcon {
    @apply text-2xl text-gray-400 cursor-pointer;
  }
  
  .playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songLeftSection .playSongIcon {
    @apply w-6 h-6 !text-gray-200 cursor-pointer;
  }
  
  .playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songDetailsWrapper {
    @apply flex flex-col justify-start items-start gap-2;
  }
  
  .playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songDetailsWrapper .title {
    @apply text-xl text-white leading-none;
    white-space: normal !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
  }
  
  .playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songDetailsWrapper .artistName {
    @apply text-xs text-gray-400 leading-none;
    white-space: normal !important;
    text-overflow: ellipsis !important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
  }
  
  .playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .deleteSongIcon {
    @apply text-2xl text-gray-400 cursor-pointer;
  }
  
  
  /* Empty queue */
  
  .playQueueDropupContainer .empytyQueueContainer  {
    @apply flex justify-center items-center px-3 py-24;
  }
  
  .playQueueDropupContainer .empytyQueueContainer span  {
    @apply text-gray-400 text-base font-semibold;
  }
  
  
  
  /* Mobile */
  
  @media (max-width: 720px) {
    .playQueueDropupContainer {
        @apply bottom-16;
    }
  }