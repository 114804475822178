.shareInfoContainer {
    @apply py-4 px-4;
}

.shareInfoContainer .horizontalDivider {
    @apply bg-gray-400 h-[0.05rem] mx-0;
}

.shareInfoContainer .embedTitle {
    @apply text-lg font-semibold;
}

.shareInfoContainer .embedTitle span {
    @apply text-[#12a0e8];
}

.shareInfoContainer p code {
    @apply text-xs text-[#666666];
}

.shareInfoContainer p a {
    @apply !text-base !text-[#12a0e8];
    white-space: pre-wrap; /* CSS3 */    
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */    
    white-space: -o-pre-wrap; /* Opera 7 */    
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.shareInfoContainer .copyIcon {
    @apply text-base text-white ml-4 cursor-pointer;
}


.shareInfoContainer .sociaShareWrapper {
    @apply flex justify-center mt-8;
}

.shareInfoContainer .sociaShareWrapper a {
    @apply min-w-[120px] rounded-full mx-2 !text-[1.05rem] capitalize;
}


/* Mobile */

@media (max-width: 640px) {
    .shareInfoContainer .sociaShareWrapper a {
        @apply min-w-[95px];
    }
  }