.sidebarParentContainer {
    @apply w-full flex flex-col gap-10 justify-start shadow-md;
    display: none; /* sidebar hidden by default and to be shown programmatically */
    overflow: hidden; /* Disable scroll. Only .parentSidebarList list will be scrollable */
    background: #111;
    z-index: 1;
    text-align: center;
    transition: width 0.03s ease-in-out;
    -webkit-transition: width 0.03s ease-in-out;
    min-width: 320px;
    max-width: 320px;
    overscroll-behavior: contain; /* Prevent Safari rubber band effect during scrolling of children*/
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox - Hide scrollbar */
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        /* Inherit height from .pageParentContainer element */
        height: inherit; /* This will only work on Firefox. -moz-fill-available will not work */
        max-height: inherit;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        height: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
}

.sidebarParentContainer .sidebarHeader {
    @apply w-full relative;
    flex-shrink: 0;
}

.sidebarParentContainer .parentSidebarList {
    @apply overflow-y-scroll relative px-1 pb-20;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    touch-action: pan-y; /* Allow only vertical scrolling (used to improve iOS scroling) */
    /* Inherit height from .sidebarParentContainer element */
    height: inherit; /* This will only work on Firefox */
    max-height: inherit;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
         /* Hide sidebar scrollbar on  Firefox as it's too thick and not possible to make ultra thin */
        scrollbar-width: none;
    }
}

.sidebarParentContainer .parentSidebarList::-webkit-scrollbar {
    width: 1px;  /* Chrome/Safari/Opera sidebar scrollbar width */
}

.sidebarParentContainer .parentSidebarList::-webkit-scrollbar-thumb {
    background-color: #1c1c1c !important; /* Chrome/Safari/Opera sidebar scrollbar color */
}

.sidebarProfilePhoto {
    @apply rounded-full w-10 h-10 object-cover;
}

.sidebarListParentItem {
    @apply flex items-center text-2xl py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded;
    @apply hover:text-white hover:bg-gray-900 transition duration-300 ease-in-out cursor-pointer;
}

.sidebarListParentItem span {
    @apply ml-3
}

.sidebarListParentItemProfile {
    @apply hover:text-white hover:bg-gray-900 transition duration-300 ease-in-out cursor-pointer;

    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        min-width: -moz-available !important;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        min-width: -webkit-fill-available !important;
    }
}

.sidebarListChildItem {
    @apply flex items-center text-xl py-6 pl-12 pr-6 h-6 ml-10 overflow-hidden !text-white text-ellipsis whitespace-nowrap;
    @apply rounded hover:!text-white hover:!bg-[#0d0d0d] transition duration-300 ease-in-out cursor-pointer;
}

.sidebarListChildItemProfile {
    @apply py-[1.35rem];
}

.sidebarListChildItem span {
    @apply ml-4;
}

.sidebarListChildItem2 {
    @apply !ml-24;
}

.sidebarListChildIcon {
    @apply w-6 h-6 mr-4;
}

.fanmailUnreadWrapper {
    @apply ml-4  w-6 h-6 rounded-full bg-[#c6013b] flex justify-center items-center;
}

.fanmailUnreadWrapper span {
    @apply text-xs text-white ml-0;
}

.routerLinkActiveChildItem {
    @apply text-2xl font-medium bg-gray-900 ;
}


.startRadioButton {
    @apply flex justify-center items-center rounded-full ml-20 my-3  px-6 pt-[1rem] pb-[1rem] mr-2 gap-4;
    @apply bg-[#171717] text-white text-xl cursor-pointer;
    @apply leading-normal hover:bg-white hover:text-gray-900 hover:shadow-lg focus:bg-white focus:shadow-lg focus:outline-none focus:ring-0;
    @apply active:bg-white active:shadow-lg transition duration-150 ease-in-out ripple-surface-white;
}

.startRadioButton svg {
    @apply text-white w-8 h-8 shrink-0;
}

.startRadioButton i {
    @apply text-white text-3xl;
}

.startRadioButton:hover svg,
.startRadioButton:hover i,
.startRadioButton:hover span  {
    @apply !text-black;
}

.sidebarTagsWrapper {
   @apply flex flex-wrap justify-start ml-32 space-x-2 space-y-1 mt-2;
}

.sidebarTags {
    @apply px-4 py-2 rounded-xl bg-[#1d1d1d] text-white font-semibold text-sm leading-none;
    @apply flex w-max cursor-pointer active:bg-gray-300 transition duration-300;
    @apply hover:bg-white hover:text-gray-800 hover:shadow-lg focus:bg-white focus:shadow-lg focus:outline-none focus:ring-0;
    margin-left: 0 !important;
}   

.sidebarTags.selected {
    @apply bg-gray-100 text-gray-800 shadow-lg;
}

.sidebarInputs {
    @apply w-full mt-0 mb-4;
}

.sidebarInputs > div {
    @apply flex justify-between items-center text-white text-lg rounded-full h-10 pl-[1.2rem] pr-[.2rem] py-0 bg-[#171717];
}

.sidebarInputs input {
    @apply w-full h-auto text-base bg-inherit text-gray-100 text-ellipsis;
    @apply focus:outline-none focus:ring-white focus:border-white block;
}

.sidebarInputsCloseIcon {
    @apply text-white h-5 w-5 top-[8px] ml-[6px] px-[5px] py-[5px];
}

.sidebarInputParent {
    @apply pt-6 pb-2;
}



.about_modal a {
    @apply mt-6 text-[#0da1e8];
}

.selectedSidebarFilter {
    @apply h-full bg-blue-800 w-[0.4rem] rounded-full mr-5;
}

.logoContainer {
    @apply mt-8 flex flex-col items-center;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.logoContainer .logoWrapper {
    @apply flex  justify-center items-center relative;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.logoContainer .logoWrapper a {
    @apply after:content-none w-[50%];
}

.logoContainer .logoWrapper svg {
    @apply w-full;
}

.logoContainer .logoWrapper .closeIconWrapper {
    @apply absolute right-2 cursor-pointer;
}

.logoContainer .logoWrapper .closeIcon {
    @apply w-8 h-8 px-1;
}

.logoContainer .logoBottomContainer {
    @apply flex justify-start gap-6 mt-6 items-center text-xl text-white;
}

.logoContainer .logoBottomContainer .betaIcon {
    @apply px-4 py-2 rounded-full bg-[#171717] text-gray-400 font-semibold text-sm leading-none;
    @apply flex w-max cursor-pointer active:bg-gray-300 transition duration-300;
}

.logoContainer .logoBottomContainer i {
    @apply text-gray-400 text-lg cursor-pointer !bg-none;
}

.logoContainer .logoBottomContainer i {
    @apply text-gray-400 text-lg cursor-pointer !bg-none;
}

.logoContainer .logoBottomContainer svg {
    @apply !text-gray-400 w-[1.125rem] h-[1.125rem] cursor-pointer;
}

.logoContainer .logoBottomContainer .mailWrapper {
    @apply flex justify-start items-start;
}

.logoContainer .logoBottomContainer .mailWrapper .unreadWrapper {
    @apply w-[.35rem] h-[.35rem] rounded-full bg-[#c6013b];
}

.logoContainer .logoBottomContainer .tooltipDiv .tooltipText {
    right: -22px;
}

.infoDropdown {
    @apply w-full mx-6 rounded-lg pl-6 pr-3 pb-6 pt-2 text-lg;
    background: #191919;
}

.infoDropdown .description {
    @apply text-gray-200 mt-5 block leading-9
}

.infoDropdown .linksList {
    @apply flex flex-col gap-6 mt-6 uppercase text-[11px];
}

.infoDropdown .linksList a {
    @apply text-[#df004c];
}


/* Footer */

.sidebarFooter {
    @apply flex flex-col text-center pb-[10px] w-full overflow-hidden;
}

/* Apply height to parent sidebarFooter when child has .show class */
.sidebarFooter:has(.collapse.show) {
    @apply min-h-[25rem];
}
/* Apply height to parent sidebarFooter when child has .hasSwitchPages class */
.sidebarFooter:has(.collapse.show.hasSwitchPages) {
    @apply min-h-[32rem];
}


.sidebarFooter .collapse {
    @apply mt-1 ml-6 overflow-y-scroll;
}

.sidebarFooter .collapse ul {
    @apply relative;
}

.sidebarFooter .sidebarFooterInnerWrapper {
    @apply pb-0;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        min-width: -moz-available !important;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        min-width: -webkit-fill-available !important;
    }
}

.sidebarFooter .switchPagesWrapper  {
    @apply flex justify-start items-center gap-3 py-6 pl-12;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.sidebarFooter .switchPagesWrapper .divider {
    @apply w-20 h-[.05rem] bg-[#2c2c2c];
}

.sidebarFooter .switchPagesWrapper span {
    @apply text-white font-semibold text-lg whitespace-nowrap;
}

.max-vh {
    /* height: calc(var(--vh, 1vh) * 100) !important;
    min-height: calc(var(--vh, 1vh) * 100) !important;
    max-height: calc(var(--vh, 1vh) * 100) !important; */
    @apply h-full;
}


  
/* Mobile */

@media (max-width: 640px) {
    .sidebarParentContainer {
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
            min-width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
            min-width: -webkit-fill-available;
        }
    }

    .sidebarFooter {
      -webkit-overflow-scrolling: touch;
      -webkit-transform: translate3d(0,0,0);
    }

    .sidebarFooter > * {
        -webkit-transform: translateZ(0);
    }

    .logoContainer .logoWrapper .closeIcon {
        @apply right-4;
    }
}