.visualizerContainer {
    @apply bg-[#111111] relative;
    height: inherit;
    z-index: 10000;
}

.visualizerContainer canvas {
    @apply absolute;
}

.visualizerContainer .visualizerControls {
    @apply absolute top-4 right-4 flex justify-end items-center gap-10;
}

.visualizerContainer .visualizerControls .visualizerControlButton {
    @apply flex justify-center items-center hover:bg-white hover:text-gray-900/75 text-gray-300 bg-[#363636] w-12 h-12 text-center rounded-full opacity-75 hover:opacity-100 cursor-pointer
    disabled:opacity-25 disabled:cursor-not-allowed p-1 m-0 transition-all ease-in-out duration-300 !z-10;
}