@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .dark-container {
        @apply relative py-12 px-20;
        background: linear-gradient(
            224.86deg,
            rgba(7, 7, 7, 0.25) 2.75%,
            rgba(17, 17, 17, 0.25) 96.04%
        );
        mix-blend-mode: normal;
        border: 1px solid rgba(151, 151, 151, 0.15);
        box-shadow: 2px 2px 6px 3px rgba(0, 0, 0, 0.18);
        border-radius: 32px;
        width: 75%;
    }

    .galaxy-container {
        background: linear-gradient(
            224.86deg,
            #48484840 2.75%,
            #11111147 96.04%
        );
        mix-blend-mode: normal;
        border-radius: 57px;
        box-shadow: 2px 2px 6px 3px rgba(0, 0, 0, 0.788378);
        padding: 0em 5em;
        width: 75%;
    }

    .nft-button-main {
        @apply cursor-pointer p-0 mt-12 mb-6 text-xl font-bold;
        background-size: 200% 200%;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
        border-radius: 35px;
        border: none;
        font-family: "MavenPro";
    }

    .nft-button-circle {
        @apply border-solid border cursor-pointer h-10 p-0 pointer-events-auto align-middle w-10;
        background: linear-gradient(
            131.33deg,
            #272727 27.19%,
            #191919 114.82%
        ) !important;
        border-color: rgba(151, 151, 151, 0.1367);
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        color: rgba(255, 0, 87, 1);
    }

    .nft-button-file {
        @apply appearance-none border-solid border cursor-pointer inline-block font-normal text-lg mx-0 mt-0 mb-4 px-4 py-[.8rem] relative text-left text-white w-full;
        font-family: "StyreneA-Regular";
        outline: 0;
        border: 1px solid rgba(151, 151, 151, 0.1367);
        border-radius: 30px;
        background: linear-gradient(52.69deg, #272727 3.93%, #191919 116.96%);
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    }

    .nft-button-tier {
        background: linear-gradient(52.69deg, #893fe4 3.93%, #be3d7a 116.96%);
        border: none;
        border-radius: 35px;
        box-shadow: 0 0 12px rgb(0 0 0 / 50%);
        cursor: pointer;
        font-family: MavenPro !important;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 24px;
        margin-top: 50px;
        padding: 0;
    }
}

#solidAppRoot {
    /* Inherit width and height from body element i.e. width: 100vh, height: 100dvh */
    width: inherit;
    height: inherit;
    max-height: inherit;
    overflow: hidden; /* Prevent scrolling. Only .singlePageWrapper will be scrollable */
    scrollbar-width: auto; /* Ensures some scrollbar width consistency on windows, mac and linux */
    scrollbar-gutter: stable; /* Prevent layout shifts when scrollbars appear */
}

#solidAppRoot #bgWrapper {
    @apply flex flex-col justify-between;
    background-color: #222222;
    overflow: hidden; /* Prevent scrolling. Only .singlePageWrapper will be scrollable */
    /* Inherit width and height from #solidAppRoot element*/
    width: inherit;
    height: inherit;
    max-height: inherit;
}

#solidAppRoot #bgWrapper .pageParentContainer {
    @apply flex justify-start items-start bg-[#111111] min-h-0;
    min-height: 0;
    flex-grow: 1; /* Take up remaining space */
    overflow: hidden; /* Prevent scrolling. Only .singlePageWrapper will be scrollable */
    /* Inherit width and height from #bgWrapper element */
    width: inherit;
    height: inherit;
    max-height: inherit;
    overscroll-behavior: contain; /* Prevent Safari rubber band effect during scrolling of children*/
}

#bgWrapper .pageParentContainer .solidPageContainer {
    container-type: inline-size;
    container-name: solidPageContainer; /* To be used with css media queries */
    @apply relative flex flex-col justify-start items-start;
    background-color: #111111 !important;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overscroll-behavior: contain; /* Prevent Safari rubber band effect during scrolling of children*/
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
        /* Inherit height from .pageParentContainer element */
        height: inherit; /* This will only work on Firefox. -moz-fill-available will not work */
        max-height: inherit;
         /* Firefox scrollbar color + track color in respective order */
        scrollbar-color: #242424 #111111;
        /* Firefox scrollbar width */
        scrollbar-width: thin;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        max-height: -webkit-fill-available;
    }
}

#bgWrapper .pageParentContainer .solidPageContainer::-webkit-scrollbar {
    display: none;
}

#bgWrapper .pageParentContainer .solidPageContainer .singlePageWrapper {
    /* Use position relative to ensure absolute elements like loaders are properly positioned. 
    Also ensure vertical scrolling is enabled. 
    The entire app's vertical scrolling is fully handled on .singlePageWrapper
    */
    @apply overflow-y-scroll flex flex-col justify-start items-start;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    overscroll-behavior: contain; /* Stop scrolling from affecting parent (used to fix iOS scroling rubber band effect) */
    touch-action: pan-y; /* Allow only vertical scrolling (used to improve iOS scroling) */
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
        height: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
    }
}

/* 100% Height Handler adapted to properly work on iOS Safari */
.height-fill-available {
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        height: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        height: -webkit-fill-available;
    }
}
/* End height Handler */

/* Scrollbar customization */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Hide the small fixed buttons at the start & end for a clean look */
::-webkit-scrollbar-button {
    display: none !important;
}

::-webkit-scrollbar-track {
    background-color: #111111; /* scrollbrar track color same as page background so it's invisible */
}
  
::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #242424 !important;
    border-radius: 3px;
}

/* Input Defaults */
[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select,
input {
    padding: initial;
    font-size: initial;
}

textarea {
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
}

/* Input Focus Defaults */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus,
input:focus,
[type="checkbox"]:focus,
[type="radio"]:focus {
    box-shadow: none !important;
}
/* End input defaults */

/* Checkbox / Radio button (both use this) */

.checkboxWrapper {
    @apply block min-h-[1.5rem] mt-6;
}

.checkboxWrapper label {
    @apply inline-flex items-center cursor-pointer;
}

.checkboxWrapper label input {
    @apply w-6 h-6 rounded-full cursor-pointer;
}

.checkboxWrapper label span {
    @apply ml-4 text-xl;
}

.checkboxWrapper label span a {
    @apply text-[#9d9d9d];
}

.musician_question_text .checkboxWrapper {
    @apply mt-4;
}

/* End Checkbox  */

.dark-container label span {
    font-size: 13px;
    color: #9b9b9b;
}

.dark-container h3 img {
    vertical-align: text-bottom;
    margin-right: 16px;
}
/* Table */
table.priceTable {
    margin: 32px 0 50px 0;
    font-family: "StyreneA-Regular";
    background-color: #121212;
    width: 100%;
    border-collapse: collapse;
    border-radius: 30px;
    border-style: hidden;
    /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #313131;
}

table.priceTable td,
table.priceTable th {
    border-bottom: 1px solid #323232;
    padding: 10px 10px;
}

table.priceTable tbody td {
    font-size: 14px;
    color: #ffffff;
}

table.priceTable thead {
    border-bottom: 2px solid #323232;
}

table.priceTable thead th {
    font-weight: bold;
    color: #ffffff;
}

.nftId span {
    background: linear-gradient(131.33deg, #272727 27.19%, #191919 114.82%);
    border: 1px solid rgba(151, 151, 151, 0.1367);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: #ff0057;
    padding: 12px;
    cursor: pointer;
}

.nftId span i {
    vertical-align: bottom;
}

/* Select dropdown */
.nftSelect {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
}

.nftSelect select {
    @apply px-4 py-[.8rem];
    display: inline-block;
    font-family: "StyreneA-Regular";
    font-size: 1.125rem;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
    outline: 0;
    border: 1px solid rgba(151, 151, 151, 0.1367);
    border-radius: 30px;
    background: linear-gradient(52.69deg, #272727 3.93%, #191919 116.96%);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1.75rem;
}

.nftSelect select::-ms-expand {
    display: none;
}

.nftSelect select:hover,
.nftSelect select:focus {
    color: #ffffff;
    background: #4c4c4c;
}

.nftSelect button:hover,
.nftSelect button:focus {
    color: #ffffff;
    background: #4c4c4c;
}

.nftSelect select:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.select_arrow {
    position: absolute;
    top: 23%;
    pointer-events: none;
    right: 24px;
    width: 14px;
    height: 14px;
    border: solid #ff0057;
    border-width: 0 4px 4px 0;
    display: inline-block;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.nftSelect select:hover ~ .select_arrow,
.nftSelect select:focus ~ .select_arrow {
    border-color: #ff0057;
}

.nftSelect select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}

.sliderRadio label,
input[type="radio"] + label {
    padding: 10px 20px !important;
}

.sliderRadio {
    position: relative;
    padding: 4px;
    margin-top: 32px;
    background: #111;
    box-shadow:
        0 -4px 4px rgba(255, 255, 255, 0.0472002),
        0 0 4px 2px rgba(0, 0, 0, 0.321252),
        inset 0 -4px 5px rgba(123, 123, 123, 0.106658),
        inset 0 4px 5px 5px rgba(0, 0, 0, 0.38182);
    border-radius: 25px;
    display: inline-block;
}

.sliderRadio input[type="radio"] {
    display: none;
}

.sliderRadio input[type="radio"]:checked + label {
    position: relative !important;
    z-index: 100 !important;
}

.ignoreCss,
.ignoreCss:checked + label {
    all: unset;
}

.ignoreCss input[type="radio"]:not([disabled]) + label {
    background-image: none;
}

.sliderRadio label {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 700;
    font-family: StyreneA-Regular;
    margin: 0 !important;
    width: 245px;
    display: inline-block;
    cursor: pointer;
    opacity: 0.5;
}

.sliderRadio .slidingPiece,
.sliderRadio input[type="radio"]:checked + label {
    background-color: #77abff;
    transition-timing-function: cubic-bezier(0.74, 0.14, 0.38, 0.99);
    color: #fff;
    border-radius: 30px;
    background: linear-gradient(
        90deg,
        #ff0057 7.13%,
        #a40038 91.09%
    ) !important;
    opacity: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.primary-background {
    background: #111111;
}

.primary-color {
    color: #ff0057 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.background-none {
    background: none !important;
}

.backButton {
    @apply flex justify-center items-center hover:bg-white hover:text-gray-900/75 text-gray-300 bg-[#363636] w-12 h-12 text-center rounded-full opacity-75 hover:opacity-100 cursor-pointer
    disabled:opacity-25 disabled:cursor-not-allowed p-1 m-0 transition-all ease-in-out duration-300 !z-10 ml-[10px];
}

.backButton svg {
    @apply w-[24px] h-[24px];
}

.mainContainer {
    @apply pl-0 pr-0;
    height: inherit;
    max-height: inherit;
    transition: all 0.5s;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
        max-width: -moz-available;
    }

    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
        max-width: -webkit-fill-available;
    }
}

.masonryListingsContainer {
    @apply px-8;
    transition: width 0.5s;
}

.masonryListingsContainer .backButton {
    @apply ml-0;
}

.solid-load-indicator-centered {
    position: absolute;
    top: 47%;
    left: 47%;
}

@media (max-width: 640px) {
    .masonryListingsContainer {
        @apply pt-8 !px-4;

        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available !important;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available !important;
        }
    }
}

.artist-meta-text {
    white-space: normal !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important; /* number of lines to show */
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
}

.nft-coming-soon {
    font-size: 13px;
    background: linear-gradient(145deg, #7732db, #0f0b13);
    padding: 7px;
    border-radius: 50px;
    vertical-align: middle;
    line-height: 1;
}

.loading-page-indicator {
    position: absolute;
    top: 47%;
    left: 47%;
    z-index: 10000000;
    display: none;
}

#primary-content > .loading-indicator {
    margin-left: 48%;
}

.disabledLink {
    pointer-events: none;
}

.collapse.show {
    visibility: unset !important;
}

/* Embla Slide Carousel */
.embla {
    margin: auto;
    --slide-height: 500px;
    --slide-spacing: 1rem;
    --slide-size: 85%;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        max-width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        max-width: -webkit-fill-available;
    }
}

.embla__viewport {
    overflow: hidden;
}

.embla__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    min-height: 400px;
    max-height: 400px;
}

.embla__slide__inner {
    height: 100% !important;
    object-fit: cover;
}

.embla__slide .imageWraper, .embla__slide .videoWraper {
    @apply relative;
    width: 100% !important;
    height: 100% !important;
}

.embla__slide img {
    @apply cursor-pointer;
}

.embla__slide img, .embla__slide video {
    @apply absolute rounded-3xl inset-0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.embla__slide .videoWraper .expandIcon {
    @apply  w-7 h-7 absolute top-4 right-4 cursor-pointer;
}

.embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
    user-select: none;
}

.embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1.8rem;
}

.embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
}
  
.embla__button {
    -webkit-tap-highlight-color: #f5f5f5;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem #111111;
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    color: #cbcbcb;
    display: flex;
    align-items: center;
    justify-content: center;
}

.embla__button:disabled {
    color: #424243;
}

.embla__button__svg {
    width: 35%;
    height: 35%;
}

.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25rem;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.embla__dot {
    -webkit-tap-highlight-color: #f5f5f5;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.embla__dot:after {
    box-shadow: inset 0 0 0 0.1rem #cbcbcb;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}

.embla__dot--selected {
    background-color: #cbcbcb;
}

/* Image viewer styling (for awesome-image-viewer package) */
.imageViewer > .container {
    position: unset !important;
}

/* End Embla slide carousel */

.enableBlur > * {
    filter: blur(1.2px);
}
.disableBlur {
    filter: blur(0);
}
/* create drop end */

.dont-show {
    display: none;
}
.show {
    display: block;
}
/* Modal */

.modalContainer {
    @apply fade fixed inset-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto;
    z-index: 99999999999;
}

.modalContainer .modalDialog {
    @apply w-auto pointer-events-none;
}

.modalContainer .modal-content {
    @apply border-none shadow-lg flex flex-col pointer-events-auto bg-[#2c2c2c] rounded-3xl outline-none text-current cursor-auto;
    z-index: 99999999999;
}

.modalContainer .modalHeader {
    @apply flex flex-shrink-0 items-center justify-end p-4 border-b border-gray-200 rounded-t-md;
}

.modalContainer .closeIcon {
    @apply box-content text-[20px] p-1 text-white border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-white hover:opacity-75 hover:no-underline cursor-pointer;
}

.modalContainer .modalTitle {
    @apply text-[2rem] font-semibold leading-normal text-white;
}

.modalContainer.kycPromptModalContainer .modalTitle {
    @apply text-left;
}

.modalContainer .modalSubTitle {
    @apply text-[15px];
}

.modalContainer .modalBody {
    @apply relative py-4 px-[3rem];
}

.modalContainer .modalBody p {
    line-height: 1.75rem;
}

.modalContainer.kycPromptModalContainer .modalBody {
    @apply py-0;
}

.modalContainer.kycPromptModalContainer .modalDescription {
    @apply leading-8 text-left;
}

.modalContainer .modalFooter {
    @apply flex flex-shrink-0 flex-wrap gap-4 items-center justify-end p-4 border-t border-gray-200 rounded-b-md;
}
.modalContainer .modal-bottom-buttons {
    @apply justify-evenly !important;
}

.modalContainer.kycPromptModalContainer .modalFooter {
    @apply gap-4 px-8 pb-12 pt-0;
}

.modalContainer .closeButton {
    @apply inline-block px-10 py-4 bg-[#141414] text-white font-medium text-lg leading-tight rounded-full hover:bg-[#111111] hover:shadow-lg focus:bg-[#111111] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#111111] active:shadow-lg transition duration-150 ease-in-out cursor-pointer;
}

.modalContainer .deleteButton {
    @apply inline-block px-10 py-4 bg-[#d60543] text-white font-medium text-lg leading-tight rounded-full hover:bg-[#c6013b] hover:shadow-lg focus:bg-[#c6013b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#c6013b] active:shadow-lg transition duration-150 ease-in-out cursor-pointer;
}

.modalContainer .stopButton {
    @apply inline-block px-10 py-4 bg-[#d60543] text-white font-medium text-lg leading-tight rounded-full hover:bg-[#c6013b] hover:shadow-lg focus:bg-[#c6013b] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#c6013b] active:shadow-lg transition duration-150 ease-in-out cursor-pointer;
}

.modalContainer .modalBody form input {
    @apply box-border block w-full rounded-full bg-[#151515] px-6 py-4 text-sm text-white placeholder-gray-400 mt-3;
    @apply focus:border focus:border-solid focus:border-gray-300 outline-none;
    border: 1px solid transparent;
}

.modal.modalContainer .modal-dialog {
    z-index: 99999999999;
    transform: none;
    min-height: 0 !important; 
}

.modal-backdrop {
    z-index: 2;
}

/* Card Badges */

.streams-logo {
    display: flex;
    justify-content: end;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.streams-logo .promoted-logo {
    @apply bg-gradient-to-r from-green-900 to-green-600 uppercase;
    z-index: 1;
    font-size: 7px;
    padding: 7px;
    border-radius: 8px;
    color: white;
    max-width: min-content;
    margin-right: 5px;
    @media (max-width: 640px) {
        left: 60%;
    }
}

.streams-logo .free-logo {
    @apply bg-gradient-to-r from-teal-900 to-teal-600 uppercase;
    z-index: 1;
    font-size: 7px;
    padding: 7px;
    border-radius: 8px;
    color: white;
    max-width: min-content;
    margin-right: 5px;
    @media (max-width: 640px) {
        left: 77%;
    }
}

.streams-logo .nft-logo {
    @apply bg-gradient-to-r from-indigo-900 to-indigo-600;
}

.tooltipDiv {
    @apply relative inline-block;
}

.tooltipDiv .tooltipText {
    @apply absolute hidden text-xs text-center text-gray-700 py-1 px-3 rounded-full bg-gray-200  whitespace-nowrap right-0 bottom-full;
}

.tooltipDiv:hover .tooltipText {
    @apply block;
}

/* RECAPTCHA V3 */
/* Hide recaptcha v3 badge */
.grecaptcha-badge {
    opacity: 0;
    height: 0 !important;
}

.grecaptcha-logo {
    visibility: hidden;
}

.v3-captcha-box {
    margin-top: 20px;
}

.v3-captcha-box captcha {
    @apply border-none;
}

.v3-captcha-box .custom-captcha._cc-dark {
    @apply bg-[#1b1a1a] border-none;
}

.v3-captcha-box
    .custom-captcha:not(._cc-loading):not(._cc-loaded)
    ._cc-checkmark {
    @apply border-none;
}

/* End recaptcha */

section.src-containers--container {
    background-color: #0f0b13;
}

#suspicious {
    @apply flex flex-col items-center justify-center;
}
#suspicious > button {
    color: #aaa;
    width: max-content;
    cursor: pointer;
    border-radius: 9999px;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1;
    background-color: #333;
    margin-top: 10px;
}

/* Rainbow animation for visualizer button */
@keyframes rainbowColors {
    0% { color: red; }
    16.666% { color: orange; }
    33.333% { color: yellow; }
    50% { color: green; }
    66.666% { color: blue; }
    83.333% { color: indigo; }
    100% { color: violet; }
}

@keyframes rainbowStroke {
    0% { stroke: red; }
    16.666% { stroke: orange; }
    33.333% { stroke: yellow; }
    50% { stroke: green; }
    66.666% { stroke: blue; }
    83.333% { stroke: indigo; }
    100% { stroke: violet; }
}

@keyframes rainbowFill {
    0% { fill: red; }
    16.666% { fill: orange; }
    33.333% { fill: yellow; }
    50% { fill: green; }
    66.666% { fill: blue; }
    83.333% { fill: indigo; }
    100% { fill: violet; }
}

.rainbowText {
    animation: rainbowColors 6s infinite;
}

.rainbowSvg svg {
    @apply w-full h-full;
}

.rainbowSvg svg path {
    animation: rainbowStroke 6s infinite;
}


/* Music actions dropup */
.sb-content {
    position: absolute !important;
    right: 0 !important;
    width: 450px !important;
    max-width: 450px !important;
}

.bottomsheet-dropup-container {
    @apply flex flex-col items-center gap-2 px-8 overflow-y-scroll;
    scrollbar-width: none;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

/* Start bottomshee CSS */
.sb-handle-container {
    @apply !pt-[14px] !pb-[5px];
}

.bottomsheet-dropup-container .dropupTopBar {
    @apply flex justify-end items-center px-[0.5rem];

    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.bottomsheet-dropup-container .dropupTopBar .dropupCloseIcon {
    @apply text-2xl text-white cursor-pointer self-end;
}

.bottomsheet-dropup-container .dropup-inner {
    height: inherit;
    width: inherit;
}


.bottomsheet-dropup-container .dropupTitleDiv {
    @apply flex flex-col justify-start items-center gap-4 my-4;
    /* Firefox-specific styles */
     @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.bottomsheet-dropup-container .dropupTitleDiv .dropupTitle {
    @apply text-white text-[20px] text-center leading-[2.5rem];
}

.bottomsheet-dropup-container .dropupTitleDiv .dropupSubtitle {
    @apply text-gray-400 text-[12px] text-center;
}

.bottomsheet-dropup-container .item {
    @apply flex flex-row justify-between gap-8 py-8 cursor-pointer;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.bottomsheet-dropup-container .item i ,
.bottomsheet-dropup-container .item svg {
    @apply text-[18px] text-white;
}

.bottomsheet-dropup-container .item span {
    @apply text-[16px] text-white;
}

.bottomsheet-dropup-container .item .spinner {
    @apply mr-0;
}

/* End bottomsheet CSS */

.description {
    resize: none;
}

/* Fade in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-item {
    transition: .9s all ease-in-out;
    opacity: 0;
}

.fade-in {
    animation: fadeIn 3.0s 1;
    animation-fill-mode: forwards;
}

/* App download prompt */
.appDownloadPromptWrapper {
    position: absolute;
    bottom: 1rem;
    right: 2%;
    left: 2%;
    z-index: 4;
    background-color: #dddcdc;
    border-radius: 24px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
}

.appDownloadPromptWrapper .titleWrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.appDownloadPromptWrapper .titleWrapper .title {
    color: #111111;
    font-size: 18px;
    text-align: center;
    margin: 0 auto;
}

.appDownloadPromptWrapper .titleWrapper .closeButton {
    cursor: pointer;
    font-size: 28px !important;
    color: #2c2c2c !important;
}

.appDownloadPromptWrapper .subtitle {
    color: #111111;
    text-align: center;
    font-size: 13px;
}

.appDownloadPromptWrapper .dontShowAgain {
    cursor: pointer;
    color: #dc004b;
    text-align: center;
    font-size: 16px;
    padding: 3px;
}

.appDownloadPromptWrapper .downloadButton {
    cursor: pointer;
    background-color: #2c2c2c;
    color: #ffffff;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1.25rem 4rem;
    font-size: 1.25rem;
    line-height: 1.5;
    display: flex;
}

.appDownloadPromptWrapper .downloadButton:hover {
    background-color: #000000;
    fill: #000000;
}

/* solid-sonner toast custom styling */
.toastContainer {
    @apply px-8 py-6;
}

.toastDescription {
    @apply leading-normal;
}

/* On VideoJS audio-only mode, hide controls UI that may show on mobile web */
#videojsAudioElement {
    @apply !hidden;
}  

/* Media upload container styling (Music upload, gallery e.t.c) */
.mediaUploadContainer {
    @apply flex justify-center items-center rounded-[2.5rem] px-6 py-24 mt-8 mb-10 border-dashed border-[1px] border-white;
    @apply transition duration-700 ease-in-out;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.mediaUploadContainer .uploadWrapper {
    @apply flex justify-center items-center flex-col text-center;
}

.mediaUploadContainer .uploadIcon {
    @apply text-[100px] text-white;
}

.mediaUploadContainer .directionsContainer {
    @apply flex flex-col justify-start items-center gap-3 mb-4;
}

.mediaUploadContainer .directionsText {
    @apply text-[1rem] text-white max-w-[400px] mt-8;
    line-height: 1.7rem;
}

.mediaUploadContainer .directionsSubtitle {
    @apply text-[.75rem] text-gray-400;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper {
    @apply flex flex-col;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .uploadItem {
    @apply flex justify-between mt-6 gap-6;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .uploadItem span {
    @apply text-white text-xl;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .uploadItem .fileTitle {
    @apply line-clamp-1 overflow-hidden text-ellipsis;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .uploadItem i {
    @apply text-red-500 text-2xl cursor-pointer;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .progressBar {
    @apply bg-green-700 h-[3px] mt-[10px];
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .failedText {
    @apply !text-red-500;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .progressBarFailed {
    @apply bg-red-500;
}

.mediaUploadContainer .rightsCheckboxContainer {
    @apply flex flex-col gap-4 justify-start items-center mt-6 w-4/5;
}

.mediaUploadContainer .rightsCheckboxContainer .rightsTitle {
    @apply text-white text-xl font-bold;
}

.mediaUploadContainer .rightsCheckboxContainer .checkboxWrapper {
    @apply mt-0;
}

/* SMALLER SCREENS */
@media (max-width: 820px) {
    .sb-content {
        position: unset !important;
        right: unset !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}

/* Style based on width of the solidPageContainer that wraps the page */
@media (max-width: 640px) {
    .hiddenMobile {
        @apply !hidden;
    }

    .modalContainer .modalTitle {
        @apply text-3xl;
    }

    .embla {
        --slide-height: 400px;
    }  

    .embla__slide {
        min-height: 300px;
        max-height: 300px;
    }

    .appDownloadPromptWrapper .titleWrapper .title {
        font-size: 16px;
    }

    .appDownloadPromptWrapper .titleWrapper .closeButton {
        font-size: 24px !important;
    }

    .modalContainer .modal-content {
        @apply !w-[90%];
    }

    ::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    .mediaUploadContainer .rightsCheckboxContainer {
        @apply w-full;
    }
}

@media (min-width: 641px) {
    .hiddenDesktop {
        @apply !hidden;
    }
}