.collapsedPlayerContainer {
    @apply absolute right-4 bottom-8;
}

.collapsedPlayerContainer .songImageContainer {
    @apply rounded-full cursor-pointer;
    border: 1.5px solid #a7a7a7;
    box-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38);
    z-index: 9999;
}

.collapsedPlayerContainer .songImageContainer .songImage {
    @apply w-[4.2rem] h-[4.2rem] min-w-[4.2rem] min-h-[4.2rem] object-cover rounded-full;
}

.collapsedPlayerContainer .songImageContainer .songImage.rotate {
    animation: collpasedPlayerSongImageRotation 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
  
@keyframes collpasedPlayerSongImageRotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.collapsedPlayerContainer .songImageContainer .songImage.pauseRotation {
    animation-play-state: paused;
}