#walletBottomsheetDropupParent {
    @apply rounded-t-[2.5rem];
}


#walletBottomsheetDropup .dropupTopBar {
    @apply justify-between;
}

#walletBottomsheetDropup .dropupTopBar .goToWalletButtonWrapper {
    @apply cursor-pointer flex justify-start items-center gap-2;
}

#walletBottomsheetDropup .dropupTopBar .goToWalletButtonWrapper i {
    @apply text-xl text-gray-400;
}

#walletBottomsheetDropup .dropupTopBar .goToWalletButtonWrapper span {
    @apply text-base text-gray-400;
}

.walletDropupContainer {
    @apply max-h-[450px] min-h-[150px] flex flex-col;
    z-index: 10000;
    container-type: inline-size;
    width: inherit;
    height: inherit;
}

/* SMALLER SCREENS */

/* Style based on width of the wallet dropup container that wraps the table */
@container (max-width: 640px) {

    .walletDropupContainer .walletTransactionsContainer {
        @apply px-4 pt-4 overflow-y-scroll my-0 rounded-b-none;
        /* Hide scrollbar */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .walletDropupContainer .walletTransactionsContainer::-webkit-scrollbar {
        display: none;
    }

    .walletDropupContainer .walletTransactionsContainer .headerSection {
        @apply flex-col items-center gap-4 mt-8;
    }

    .walletDropupContainer .walletTransactionsContainer .headerSection .title {
        @apply text-xl mt-6;
    }

    .walletDropupContainer .walletTransactionsContainer .headerSection .infoSection {
        @apply flex-col;
    }

    .walletDropupContainer .walletTransactionsContainer .headerSection .infoSection a.buyJAMButton {
        @apply cursor-pointer flex;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem {
        @apply flex-col gap-6 px-6;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper {
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .firstDiv {
        @apply gap-2;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
        @apply justify-between;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .title {
        @apply text-xs;
    }
    
    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistText {
        @apply text-sm;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .subTitle {
        @apply text-xs max-w-[10rem];
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer {
        @apply justify-end;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .timeCount {
        @apply text-xs overflow-visible !block;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistImage {
        @apply !hidden;
    }
    

    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper {
        @apply gap-3 justify-between;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
        @apply flex-row-reverse justify-between gap-4;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper {
        @apply min-w-fit;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .timeCount {
        @apply !hidden;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper {
        @apply flex-row justify-start gap-1 min-w-fit;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .tokenAmount {
        @apply text-xs;
    }
    
    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .usdAmount {
        @apply text-xs;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .listenTime {
        @apply min-w-fit text-xs;
    }

    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .statusIcon {
        @apply hidden;
    }

    .walletDropupContainer  .walletTransactionsContainer .loadMore {
        @apply flex justify-center mt-3;
    }
    
    .walletDropupContainer .walletTransactionsContainer .loadMore span {
        @apply cursor-pointer text-lg;
    }
}



/* Smaller screen */

/* Mobile */

@media (max-width: 720px) {
    .walletDropupContainer {
        @apply bottom-16;
    }
}
