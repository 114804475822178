.playerControlsContainer {
    @apply flex justify-between items-center gap-16;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

/* Song Details */

.playerControlsContainer .songContainer {
    @apply flex justify-start items-center gap-3 pl-28;
}

.playerControlsContainer .songContainer.hideSongContainer {
    @apply invisible;
}

.playerControlsContainer .songContainer .songImage {
    @apply w-[5.5rem] h-[5.5rem] min-w-[5.5rem] min-h-[5.5rem] object-cover rounded-full cursor-pointer;
}

.playerControlsContainer .songContainer .songImage.rotate {
    animation: songImageRotation 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
  
@keyframes songImageRotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.playerControlsContainer .songContainer .songImage.pauseRotation {
    animation-play-state: paused;
}

.playerControlsContainer .songContainer .songDetailsWrapper {
    @apply flex flex-col justify-center items-start gap-2 max-w-[45%];
}

.playerControlsContainer .songContainer .songDetailsWrapper .songName {
    @apply text-white text-xl font-semibold cursor-pointer;
}

.playerControlsContainer .songContainer .songDetailsWrapper .artistName {
    @apply text-gray-400 text-base cursor-pointer;
}

.playerControlsContainer .songContainer .songDetailsWrapper .songName,
.playerControlsContainer .songContainer .songDetailsWrapper .artistName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis!important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
    line-clamp: 1!important;
    display: inline-block;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        max-width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        max-width: -webkit-fill-available;
    }
}

.playerControlsContainer .songContainer .playerActionButtonsWrapper {
    @apply flex flex-col gap-8 justify-end items-end;
}

.playerControlsContainer .songContainer .playerActionButtonsWrapper .collapseIcon {
    @apply text-[#a7a7a7] text-[2rem] font-semibold cursor-pointer;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper {
    @apply relative;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper .actionButtonsIcon {
    @apply hidden text-[#7c7c7c] text-2xl cursor-pointer;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons {
    @apply flex justify-start items-start gap-6;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons i {
    @apply text-[#7c7c7c] text-2xl cursor-pointer leading-none;
}

/* Visualizer Icon - mobile */
.playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons .visualizerIcon {
    @apply hidden;
}




/* Song Details */

.playerControlsContainer .centerDiv {
    @apply absolute flex flex-col justify-center items-center gap-3;
    width: 30%;
    left: 50%;
    margin-left: -15%;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper {
    @apply flex justify-center items-center gap-12;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper .playIcon {
    @apply w-16 h-16 rounded-full bg-[#202020] flex justify-center items-center cursor-pointer;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper .playIcon svg {
    @apply !text-white w-6 h-6 cursor-pointer;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper .skipIcon {
    @apply !text-[#7c7c7c] w-7 h-7 cursor-pointer;
}

/* Shuffle/Repeat Icons - Mobile */
.playerControlsContainer .centerDiv .playSkipControlsWrapper .shuffleIcon,
.playerControlsContainer .centerDiv .playSkipControlsWrapper .repeatIcon {
    @apply hidden;
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer {
    @apply flex justify-start items-center gap-4;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer.hideTrackerVolumeControls {
    @apply hidden;
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper {
    @apply flex justify-start items-center gap-5;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .timeElapsed,
.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .timeSongLength {
    @apply text-gray-400 text-xs min-w-[2rem];
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .trackerWrapper {
    @apply relative h-[.25rem] cursor-pointer bg-[#1C1C1C] rounded-full flex justify-start items-center;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .trackerWrapper .tracker {
    @apply h-[.25rem] cursor-pointer bg-[#ff0057] rounded-full flex justify-end items-center;
    transition: width 1s ease;
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .trackerWrapper .dragIcon {
    @apply absolute w-4 h-4 cursor-pointer bg-white rounded-full;
    transition: left 1s ease;
}



/* Smaller screen */

@media (max-width: 1360px) {
    .playerControlsContainer .songContainer .songDetailsWrapper {
        @apply max-w-[45%];
    }
}

@media (max-width: 1280px) {

    .playerControlsContainer .songContainer .songDetailsWrapper {
        @apply max-w-[300px];
    }
    
    .playerControlsContainer .songContainer .songActionButtonsWrapper .actionButtonsIcon {
        @apply block;
    }

    .playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons {
        @apply hidden absolute bottom-6 flex-col items-center justify-end px-6 py-4 bg-[#242424] rounded-[1.5rem];
        z-index: 10000;
    }

    .playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons.showActionButtonsDropup {
        @apply flex;
    }
}

@media (max-width: 960px) {
    
    .playerControlsContainer .songContainer .songImage {
        @apply w-[4.5rem] h-[4.5rem] min-w-[4.5rem] min-h-[4.5rem];
    }

    .playerControlsContainer .songContainer .songDetailsWrapper {
        @apply max-w-[115px];
    }

    .playerControlsContainer .songContainer .songDetailsWrapper .songName {
        @apply text-lg;
    }

    .playerControlsContainer .centerDiv {
        width: 28%;
        left: 50%;
        margin-left: -14%;
    }

    .playerControlsContainer .centerDiv .playSkipControlsWrapper {
        @apply gap-8;
    }

}


/* Mobile */

@media (max-width: 720px) {

    .playerControlsContainer {
        @apply flex-col justify-start items-start gap-4 px-4;
    }

    .playerControlsContainer .songContainer {
        @apply pl-0;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .playerControlsContainer .songContainer.hideSongContainer {
        @apply hidden;
    }

    .playerControlsContainer .songContainer .songDetailsWrapper {
        min-width: 0;

        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
            max-width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
            max-width: -webkit-fill-available;
        }
    }

    .playerControlsContainer .songContainer .songActionButtonsWrapper .actionButtonsIcon {
        @apply hidden;
    }

    .playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons {
        @apply flex flex-row items-end p-0 bg-transparent;
        position: unset;
    }

    .playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons .visualizerIcon {
        @apply w-[1.4rem] h-[1.4rem] !text-[#7c7c7c] cursor-pointer block;
    }

    .playerControlsContainer .centerDiv {
        @apply flex-col-reverse ml-0 gap-4;
        position: unset;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .playerControlsContainer .centerDiv .playSkipControlsWrapper {
        @apply gap-8;
    }

    /* Shuffle/Repeat Icons - Mobile */
    .playerControlsContainer .centerDiv .playSkipControlsWrapper .shuffleIcon,
    .playerControlsContainer .centerDiv .playSkipControlsWrapper .repeatIcon {
        @apply w-7 h-7 cursor-pointer block;
    }


}
