.headerBarParentContainer {
    @apply pt-4 pb-1 px-8 flex justify-between items-center gap-16 bg-[#111111];
    display: none; /* header bar hidden by default and to be shown programmatically */
    box-shadow: 0 0 2px #000000;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

/* In the right of the header bar we'll have the search bar, logo, and sidebar toggle button */
.headerBarParentContainer .rightSectionContainer {
    @apply w-3/6 flex justify-start items-center gap-3;
}

/* Small tune logo */
.headerBarParentContainer .rightSectionContainer .sidebarToggleDiv {
    @apply flex justify-start items-center gap-2; 
}

.headerBarParentContainer .rightSectionContainer .tuneLogoSmall {
    @apply w-4 h-4 cursor-pointer;
}

/* Sidebar toggle icon */
.headerBarParentContainer .rightSectionContainer .sidebarCloseOpenToggleIcon {
    @apply w-8 h-8 text-[#ff0057] cursor-pointer;
}

/* Search bar */
.headerBarParentContainer .rightSectionContainer .searchBarContainer {
    @apply flex justify-start items-center gap-3 text-white text-xl rounded-full h-10 px-9 py-[0.9rem] bg-[#222222];
    min-width: 0;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer i  {
    @apply text-white text-lg;
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer input  {
    @apply text-white text-lg h-full bg-inherit outline-none;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer::-webkit-search-decoration {
    display: none;
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer .searchButton {
    @apply hidden;
}


/* Dropdown menu parent container */
.headerBarParentContainer .menuParentContainer {
    @apply relative;
    max-width: 33%;
}

/* Profile image and name */
.headerBarParentContainer .menuParentContainer .profileDetailsContainer  {
    @apply flex justify-end items-center cursor-pointer gap-5;
}

.headerBarParentContainer .profileDetailsContainer .profileImage {
    @apply w-[3.5rem] h-[3.5rem] min-w-[3.5rem] min-h-[3.5rem] object-cover rounded-full;
}

.headerBarParentContainer .profileDetailsContainer .textDetailsWrapper {
    @apply flex flex-col justify-center items-end gap-1;
}

.headerBarParentContainer .profileDetailsContainer .textDetailsWrapper .profileName {
    @apply text-white font-semibold leading-normal;
    font-size: 1.15rem;
    white-space: normal !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
}

.headerBarParentContainer .profileDetailsContainer .textDetailsWrapper .profileType {
    @apply text-gray-400 text-base capitalize;
    white-space: normal !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
}

.headerBarParentContainer .profileDetailsContainer .optionsDropdownIcon {
    @apply w-3 h-3 text-gray-500;
}

/* Top-right options dropdown */
.headerBarParentContainer .optionsDropdownContainer {
    @apply absolute w-full bg-[#1c1c1c] flex flex-col justify-start items-center gap-3 rounded-b-[30px];
    min-width: 225px;
    top: 4.1rem;
    right: 0rem;
    z-index: 1000000000;
}

/* Dropdown Menu items */
.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper {
    @apply flex flex-col gap-2 px-3 pt-2 pb-3 rounded-t-none;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul {
    @apply overflow-y-scroll;
    max-height: 26rem;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul::-webkit-scrollbar {
    display: none;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li {
    @apply cursor-pointer rounded-full hover:bg-gray-900 py-1;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li a,
.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li .innerDiv {
    @apply flex items-center text-base gap-4 px-4 py-3 overflow-hidden !text-white text-ellipsis whitespace-nowrap;
    @apply hover:!text-white transition duration-300 ease-in-out cursor-pointer;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li i,
.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li svg {
    @apply w-6 h-6;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li span {
    @apply text-white text-base leading-loose;
    white-space: normal !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper .routerLinkActiveChildItem {
    @apply rounded-full bg-gray-900;
}

/* Create-account/Login container for logged out users*/
.headerBarParentContainer .onboardingOptions {
    @apply flex justify-end items-center gap-8;
}

.headerBarParentContainer .onboardingOptions .loginButton {
    @apply flex justify-center items-center rounded-full bg-[#2c2c2c] px-6 py-4 text-white text-xl cursor-pointer;
    @apply leading-normal hover:bg-white hover:text-gray-900 hover:shadow-lg hover:fill-gray-900;
}

.headerBarParentContainer .onboardingOptions .registerButton {
    @apply flex justify-center items-center rounded-full bg-[#efefef] px-6 py-4 text-gray-900 text-xl cursor-pointer;
    @apply leading-normal hover:bg-white hover:text-gray-900 hover:shadow-lg;
}

/* Switch profile dropdown menu */
.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper {
    @apply relative flex flex-col justify-start items-center px-3 pt-6;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper {
    @apply flex justify-center items-center gap-2 cursor-pointer;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper span {
    @apply text-gray-400 text-base leading-normal;
    white-space: normal !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper i,
.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper svg {
    @apply w-6 h-6 text-gray-400 leading-normal;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu {
    @apply absolute rounded-lg flex flex-col gap-4 px-2 py-4 mx-4 bg-[#161616];
     /* Firefox-specific styles */
     @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu .titleWrapper {
    @apply relative w-full flex justify-center items-center;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu .titleWrapper span {
    @apply text-gray-400 text-base;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu .closeIcon {
    @apply text-[1.3rem] absolute right-0 cursor-pointer;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list {
    @apply flex flex-col gap-3 justify-start items-start;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem {
    @apply flex justify-start items-center gap-3 px-2 py-2 rounded-full cursor-pointer hover:bg-[#232323];
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem img {
    @apply w-6 h-6 rounded-full;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem span {
    @apply text-lg text-white;
}


/* LARGER SCREENS */
/* Style based on width of the solidPageContainer that wraps the page */
@container solidPageContainer (min-width: 640px) {
    .headerBarParentContainer .rightSectionContainer .tuneLogoSmall {
        @apply hidden;
    }
}

/* SMALLER SCREENS */
/* Style based on width of the solidPageContainer that wraps the page */
@container solidPageContainer (max-width: 640px) {
    .headerBarParentContainer {
        @apply  px-4 pb-2 gap-4;
    }

    /* class below will only added when user  is logged out */
    .headerBarParentContainer.optimizeHeaderForMobile {
        @apply flex-col; 
    }

    .headerBarParentContainer .rightSectionContainer {
        min-width: 0;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .headerBarParentContainer .profileDetailsContainer .textDetailsWrapper {
        @apply hidden;
    }

    .headerBarParentContainer .profileDetailsContainer .profileImage {
        @apply w-[3rem] h-[3rem] min-w-[3rem] min-h-[3rem];
    }

    .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul {
        max-height: 22rem;
    }

    .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper {
        @apply gap-1;
    }
    
    .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper i,
    .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper svg {
        @apply w-4 h-4;
    }
}