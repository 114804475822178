.volumeControlsWrapper {
    @apply flex justify-end items-center gap-2;
}

.volumeControlsWrapper.volumeHiddenOnDesktop {
    @apply hidden;
}

.volumeControlsWrapper.volumeHiddenOnMobile {
    @apply flex;
}

.volumeControlsWrapper svg {
    @apply w-7 h-7 !text-[#7c7c7c] cursor-pointer;
}

.volumeControlsWrapper .volumeTrackerWrapper {
    @apply hidden relative w-[60px] h-[.25rem] cursor-pointer bg-[#1C1C1C] rounded-full justify-start items-center;
}

.volumeControlsWrapper .volumeTrackerWrapper.showVolumeTracker {
    @apply flex;
}

.volumeControlsWrapper .volumeTrackerWrapper .tracker {
    @apply h-[.25rem] cursor-pointer bg-[#ff0057] rounded-full flex justify-end items-center;
    transition: width 1s ease;
}

.volumeControlsWrapper .volumeTrackerWrapper .tracker .dragIcon {
    @apply absolute w-2 h-2 cursor-pointer bg-white rounded-full;
    transition: left 1s ease;
}


/* Smaller screen */

@media (max-width: 1280px) {
    
}

@media (max-width: 960px) {
    .volumeControlsWrapper {
        @apply relative flex-col-reverse;
    }

    .volumeControlsWrapper .volumeTrackerWrapper {
        @apply absolute bottom-[25px] w-[.25rem] h-[60px] flex-col justify-end;
    }

    .volumeControlsWrapper .volumeTrackerWrapper .tracker {
        @apply w-[.25rem] h-0 flex-col;
        transition: height 1s ease;
    }

    .volumeControlsWrapper .volumeTrackerWrapper .tracker .dragIcon {
        @apply w-4 h-4;
        transition: bottom 1s ease;
    }
}


/* Mobile */

@media (max-width: 720px) {

    .volumeControlsWrapper.volumeHiddenOnDesktop {
        display: flex;
    }

    .volumeControlsWrapper.volumeHiddenOnMobile {
        display: none;
    }
}