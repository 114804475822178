.walletTransactionsContainer {
    @apply flex flex-col rounded-[2.5rem] bg-[#242424] px-8 pt-12 pb-8 mt-8 mb-10;
}

/* Wallet container Header */
.walletTransactionsContainer .headerSection {
    @apply flex flex-col justify-start items-center mb-8 gap-8;
}

.walletTransactionsContainer .headerSection .title {
    @apply text-white text-[1.7rem] font-bold;
}

.walletTransactionsContainer .headerSection .infoSection {
    @apply flex flex-wrap justify-center gap-3;
}

.walletTransactionsContainer .headerSection .infoSection div,
.walletTransactionsContainer .headerSection .infoSection a {
    @apply flex justify-center items-center py-3 px-8 rounded-full text-base text-white bg-[#343434] hover:bg-[#474747];
}

.walletTransactionsContainer .headerSection .infoSection div span,
.walletTransactionsContainer .headerSection .infoSection a span,
.walletTransactionsContainer .headerSection .infoSection a svg {
    @apply mr-4 text-xl;
}

.walletTransactionsContainer .headerSection .infoSection a.txExplorerBtn span {
    @apply text-[#343333];
}

/* Transaction  */

.walletTransactionsContainer .transactionItem {
    @apply mt-1 flex justify-between items-center rounded-xl bg-[#181818] px-6 py-3 shadow-lg;
}

/* Left Wrapper */
.walletTransactionsContainer .transactionItem .leftWrapper {
    @apply flex justify-start items-center gap-6;
}

/* First Div */
.walletTransactionsContainer .transactionItem .leftWrapper .firstDiv {
    @apply flex justify-start items-center gap-3;
}

/* Image */
.walletTransactionsContainer .transactionItem .leftWrapper .firstDiv img {
    @apply w-10 h-10 object-cover rounded-full;
}

.walletTransactionsContainer .transactionItem .leftWrapper .firstDiv .imagePlaceholder {
    @apply w-10 h-10 rounded-full flex justify-center items-center;
}

.walletTransactionsContainer .transactionItem .leftWrapper .firstDiv .imagePlaceholder i {
    @apply text-white text-xs font-semibold;
}




.walletTransactionsContainer .transactionItem .leftWrapper {
    @apply flex justify-start items-center gap-3;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
    @apply flex justify-start items-center gap-2;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer {
    @apply flex justify-start items-center gap-2;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .title {
    @apply text-white text-sm;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .subTitle {
    @apply text-gray-400 text-sm;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer {
    @apply flex justify-start items-center gap-2;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .timeCount {
    @apply !hidden;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistImage img {
    @apply w-5 h-5 object-cover rounded-full;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistText {
    @apply text-gray-400 text-sm;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper a,
.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper span {
    white-space: normal !important;
    text-overflow: ellipsis !important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important; /* number of lines to show */
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
}


/* Right Wrapper */

.walletTransactionsContainer .transactionItem .rightWrapper {
    @apply flex justify-end items-center gap-8;
}

/* Tranctions */


/* Tx Info */
.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
    @apply flex justify-end items-center gap-8;
}

/* Tx Ids */
.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper {
    @apply flex items-center justify-end gap-1 min-w-[4rem];
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper a,
.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper span {
    @apply text-white text-xs uppercase;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .timeCount {
    @apply text-white text-sm min-w-[3rem];
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper {
    @apply flex flex-col justify-center items-end gap-2 min-w-[7.8rem] text-white;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .tokenAmount {
    @apply text-inherit text-sm;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .usdAmount {
    @apply text-gray-400 text-sm;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionTimeDiv {
    @apply flex flex-col gap-2 items-end min-w-[11rem];
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionTime {
    @apply text-gray-300 text-sm;
}

.walletTransactionsContainer .transactionItem .rightWrapper .listenTimeDiv {
    @apply flex gap-2 justify-end items-start;
}

.walletTransactionsContainer .transactionItem .rightWrapper .listenTimeDiv i {
    @apply text-sm text-gray-500;
}

.walletTransactionsContainer .transactionItem .rightWrapper .statusIcon {
    @apply text-2xl;
}

.filterContainer {
    @apply flex justify-end mb-2;
}

.filterContainer .dropdown-menu {
    @apply relative bg-[#111111] hidden text-gray-700 mr-2 py-2 rounded-b-[30px];
    width: 200px;
}

.filterContainer .dropdown-menu ul {
    @apply absolute w-full bg-[#111111];
}

.filterContainer .dropdown-menu .list-item {
    @apply bg-[#111111] text-white text-lg hover:bg-gray-400 hover:text-gray-700 hover:rounded-full py-4 px-4 hover:px-6 block whitespace-nowrap cursor-pointer;

}

.filterContainer .dropdown-menu .list-item-title {
    @apply text-sm text-[#385bd0] hover:bg-[#111111] hover:text-[#385bd0] cursor-auto;
}


/* Multile TX Links dropdown */
.walletTransactionsContainer .dropdownContainer {
    @apply w-full relative bg-[#222222] rounded-t-[30px] rounded-b-[30px];
}

.walletTransactionsContainer .dropdownContainer .selectionField {
    @apply flex gap-6 items-center justify-between text-white text-lg rounded-full h-12 px-8 bg-[#1d1d1d] cursor-pointer;
    @apply focus:border focus:border-solid focus:border-gray-300 outline-none;
}

.walletTransactionsContainer .dropdownContainer .selectionField div {
    @apply flex gap-4 justify-start items-center;
}

.walletTransactionsContainer .dropdownContainer .selectionField img {
    @apply w-10 h-10 rounded-full;
}

.walletTransactionsContainer .dropdownContainer .selectionField span {
    -webkit-line-clamp: 1;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
    overflow-x: hidden;
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown {
    @apply w-full flex justify-center flex-wrap absolute;
    z-index: 9999999999;
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper {
    @apply flex flex-col gap-2 bg-[#222222] px-3 pt-2 pb-3 rounded-t-none rounded-b-[30px];
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown input {
    @apply text-white h-14 px-6 bg-[#1e1e1e] text-[13px] block focus:border-[0px];
    outline: none !important;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul {
    @apply overflow-y-scroll;
    max-height: 18rem;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul li {
    @apply flex justify-start gap-3 items-center cursor-pointer border-white rounded-full hover:bg-[#393939];
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul li .itemName {
    @apply text-white text-[0.9rem] leading-loose px-4 py-2;
    text-transform: none;
    -webkit-line-clamp: 1;
    text-wrap: nowrap;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

/* SMALLER SCREENS */

/* Style based on width of the solidPageContainer that wraps the page */
@container solidPageContainer (max-width: 640px) {

    .walletTransactionsContainer {
        @apply px-6;
    }

    .walletTransactionsContainer .headerSection {
        @apply flex-col items-center gap-4 mt-8;
    }

    .walletTransactionsContainer .headerSection .infoSection {
        @apply flex-col;
    }

    .walletTransactionsContainer .transactionItem {
        @apply flex-col gap-6;
    }

    .walletTransactionsContainer .transactionItem .leftWrapper {
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .walletTransactionsContainer .transactionItem .leftWrapper .firstDiv {
        @apply gap-2;
    }

    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
        @apply justify-between;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .title {
        @apply text-xs;
    }
    
    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistText {
        @apply text-sm;
    }

    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .subTitle {
        @apply text-xs max-w-[10rem];
    }

    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer {
        @apply justify-end gap-1;
    }

    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .timeCount {
        @apply text-white text-xs overflow-visible !block;
    }

    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistImage {
        @apply !hidden;
    }
    

    .walletTransactionsContainer .transactionItem .rightWrapper {
        @apply gap-3 justify-between;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
        @apply flex-row-reverse gap-4;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper {
        @apply min-w-fit;
    }
    
    .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .timeCount {
        @apply hidden;
    }

    .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper {
        @apply flex-col justify-start gap-1 min-w-fit;
    }

    .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .tokenAmount {
        @apply text-xs;
    }
    
    .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .usdAmount {
        @apply text-xs;
    }

    .walletTransactionsContainer .transactionItem .rightWrapper .listenTime {
        @apply min-w-fit text-xs;
    }

    .walletTransactionsContainer .transactionItem .rightWrapper .statusIcon {
        @apply hidden;
    }
}