#privacy-terms-of-use-modal{
  height: 550px;
  color: white;
  background: #181818;
  border-radius: 15px;
}
@media (min-width: 540px){
  #privacy-terms-of-use-modal{
    width: max-content;
  }
}
.md-content{
  height: 70% !important;
  overflow-y: scroll !important;
  box-shadow: none !important;
  width: 97%;
}
.privacy-terms-of-use-modal{
  overflow-y: hidden !important;
}
#privacy-terms-of-use-modal>.radio-form{
  padding: 15px 30px 15px;
  margin:10px;
}
#radio-privacy{
  display: flex;
}
#radio-privacy>input{
  opacity: 1;
  left: 0;
  width: 20px;
  margin-right: 20px
}
#submit-privacy-terms-of-use {
  padding: 10px 20px;
  border-radius: 30px;
  background: #363636;
    margin-top: 10px;
}
.blink {
  animation: blink-animation 200ms steps(5, start) infinite;
  -webkit-animation: blink-animation 200ms steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}