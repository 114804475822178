#addToPlaylistBottomsheetDropupParent.sb-content {
    @apply bg-[#242424]
}

#addToPlaylistBottomsheetDropupParent .sb-handle-container {
    @apply !py-[14px];
}

.addToPlaylistDropupContainer {
    @apply max-h-[500px] min-h-[500px] rounded-t-[1.5rem] flex flex-col;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
    z-index: 10000;
    container-type: inline-size;
}

.addToPlaylistDropupContainer .addToPlaylistTopBar {
    @apply pt-4 left-4 right-4 flex items-center bg-[#242424];
     /* Firefox-specific styles */
     @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.addToPlaylistDropupContainer .addToPlaylistTopBar .dropupTitle {
    @apply text-[1.3rem] text-white font-semibold;
    margin: 0 auto;
}

.addToPlaylistDropupContainer .addToPlaylistTopBar .addToPlaylistDropupCloseIcon {
    @apply text-2xl text-white cursor-pointer;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent {
    @apply flex flex-col gap-8 justify-start items-center mt-6 px-8 py-0 bg-[#242424] pb-8;
     /* Firefox-specific styles */
     @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox {
    @apply rounded-[30px] bg-[#2f2f2f] px-16 py-8 flex flex-col gap-8 justify-start items-center min-h-[239px] max-h-[239px];
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .selectedSaveOptionTitleWrapper {
    @apply flex gap-8 justify-center items-center;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .selectedSaveOptionTitleWrapper span {
    @apply text-[1rem] text-gray-400 font-medium;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .selectedSaveOptionTitleWrapper i {
    @apply text-[1rem] text-gray-200;
}


.addToPlaylistDropupContainer .addToPlaylistMainContent .alternativeOptionTitleWrapper {
    @apply flex gap-4 justify-center items-center cursor-pointer;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .alternativeOptionTitleWrapper span {
    @apply text-[1.1rem] text-white font-semibold;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .alternativeOptionTitleWrapper i {
    @apply text-[1.1rem] text-white;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .existingPlaylistsContainer {
    @apply flex flex-col justify-start items-center gap-1 overflow-y-scroll;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem {
    @apply flex justify-center items-center gap-4 px-8 rounded-full bg-[#5d5d5d] cursor-pointer;
     /* Firefox-specific styles */
     @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem span {
    @apply text-[1rem] text-white py-[1.7rem];
    line-height: 0;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem i {
    @apply text-[1.5rem] text-[#ff0055dc];
}

/* Input */
.addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem {
    @apply mt-4 flex flex-col gap-4 items-center;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem .inputLabel {
    @apply text-sm text-white;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem .inputInnerWrapper {
    @apply flex justify-start items-center;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .inputInnerWrapper input  {
    @apply text-white text-base rounded-full items-center px-12 py-4 bg-[#1e1e1e] text-center;
    @apply focus:border focus:border-solid focus:border-gray-300 outline-none;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .submitButton {
    @apply px-24 py-6 flex justify-center items-center rounded-full bg-white cursor-pointer;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .noPlaylistsMessage {
    @apply text-center text-white text-[1rem];
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .submitButton span {
    @apply text-[1.1rem] text-gray-800;
}

/* SMALLER SCREENS */

/* Style based on width of the add to playlist dropup container that wraps the table */
@container (max-width: 640px) {

   
}

@media (max-width: 640px) {
    .addToPlaylistDropupContainer .addToPlaylistMainContent {
        @apply px-4;
    }
    
    .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox {
        @apply pt-8 pb-4 px-4;
    }

    .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem span {
        @apply py-[1.5rem];
    }

    .addToPlaylistDropupContainer .addToPlaylistMainContent .submitButton {
        @apply px-14;
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }
}