.trackerAndTimeWrapper {
    @apply flex justify-start items-center gap-5;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.trackerAndTimeWrapper .timeElapsed,
.trackerAndTimeWrapper .timeSongLength {
    @apply text-gray-400 text-xs min-w-[2rem];
}

.trackerAndTimeWrapper .trackerWrapperOuter {
    @apply relative h-[1rem] cursor-pointer flex justify-start items-center;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper {
    @apply relative h-[.25rem] cursor-pointer bg-[#1C1C1C] rounded-full flex justify-start items-center;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper .tracker {
    @apply h-[.25rem] cursor-pointer bg-[#ff0057] rounded-full flex justify-end items-center;
    transition: width 1s ease;
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper .dragHandle {
    @apply absolute cursor-pointer flex flex-col justify-center items-center;
    transition: left 1s ease;
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper .dragHandle .dragIcon {
    @apply w-4 h-4 bg-white rounded-full;
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper .dragHandle .dragTimeTooltip {
    @apply absolute bg-[#ff0057] rounded-2xl px-[0.7rem] py-[0.4rem] text-white text-[0.7rem] bottom-5;
}