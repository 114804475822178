.fullScreenPlayerParentWrapper {
    @apply h-screen w-screen bg-[#111111] relative flex justify-center items-center;
    z-index: 10000;
}

.fullScreenPlayerParentWrapper .fullScreenPlayerCloseIcon {
    @apply absolute top-3 right-4 flex justify-center items-center hover:bg-white hover:text-gray-900/75 text-gray-300 bg-[#363636] w-12 h-12 text-center rounded-full opacity-75 hover:opacity-100 cursor-pointer
    disabled:opacity-25 disabled:cursor-not-allowed p-1 m-0 transition-all ease-in-out duration-300 !z-10;
}

.fullScreenPlayerParentWrapper video {
    @apply absolute h-screen w-screen object-cover bg-no-repeat bg-cover;
}

.fullScreenPlayerContainer {
    @apply px-32 relative;
    @apply flex flex-col justify-start items-center gap-32;
    background: linear-gradient(to bottom, transparent, #111111);
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.fullScreenPlayerContainer .songDetailsContainer {
    @apply flex flex-col justify-start items-center gap-4;
}

.fullScreenPlayerContainer .songDetailsContainer .songName {
    @apply text-3xl text-white font-semibold;
}

.fullScreenPlayerContainer .songDetailsContainer .artistName {
    @apply text-xl text-gray-400;
}

.fullScreenPlayerContainer .songDetailsContainer .songName,
.fullScreenPlayerContainer .songDetailsContainer .artistName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis!important;
    word-break: break-all;  /* Ensure ellipsis for long words by breaking them */
    line-clamp: 1!important;
    display: inline-block;

    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        max-width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        max-width: -webkit-fill-available;
    }
}

.fullScreenPlayerContainer .songDetailsContainer .songImage {
    @apply w-[22rem] h-[22rem] min-w-[22rem] min-h-[22rem] mt-12 object-cover rounded-full cursor-pointer;
}

.fullScreenPlayerContainer .songDetailsContainer .songImage.rotate {
    animation: songImageRotation 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
  
@keyframes songImageRotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.fullScreenPlayerContainer .songDetailsContainer .songImage.pauseRotation {
    animation-play-state: paused;
}

.fullScreenPlayerContainer .playerControlsContainer {
    @apply w-3/4 flex flex-col gap-12 items-center;
}

.fullScreenPlayerContainer .playerControlsContainer.videoShowing {
    @apply mb-12;
}

.fullScreenPlayerContainer .playerControlsContainer .trackerAndVolumeControlsWrapper {
    @apply flex justify-between items-center gap-8;
    /* Firefox-specific styles */
    @supports (-moz-appearance: none) {
        width: -moz-available;
    }
    /* WebKit-specific styles - Safari, Chrome and Opera */
    @supports (-webkit-appearance: none) {
        width: -webkit-fill-available;
    }
}

.fullScreenPlayerContainer .playerControlsContainer .trackerAndVolumeControlsWrapper .volumeVisualizerIconsWrapper {
    @apply flex justify-end items-center gap-8;
}

.fullScreenPlayerContainer .playerControlsContainer .trackerAndVolumeControlsWrapper .volumeVisualizerIconsWrapper .visualizerIcon {
    @apply w-[1.4rem] h-[1.4rem] !text-[#7c7c7c] cursor-pointer;
} 

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper {
    @apply flex justify-center items-center gap-36;
}

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .playIcon {
    @apply w-16 h-16 rounded-full bg-[#202020] flex justify-center items-center cursor-pointer;
}

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .playIcon svg {
    @apply !text-white w-6 h-6 cursor-pointer;
}

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .skipIcon {
    @apply !text-[#7c7c7c] w-7 h-7 cursor-pointer;
}

/* Shuffle/Repeat Icons */
.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .shuffleIcon,
.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .repeatIcon {
    @apply w-7 h-7 cursor-pointer block;
}





/* Mobile */

@media (max-width: 640px) {

    .fullScreenPlayerContainer {
        @apply px-8;
    }

    .fullScreenPlayerContainer .playerControlsContainer {
       /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .fullScreenPlayerContainer .playerControlsContainer.videoShowing {
        @apply mb-28;
    }

    .fullScreenPlayerContainer .songDetailsContainer {
        /* Firefox-specific styles */
        @supports (-moz-appearance: none) {
            width: -moz-available;
        }
        /* WebKit-specific styles - Safari, Chrome and Opera */
        @supports (-webkit-appearance: none) {
            width: -webkit-fill-available;
        }
    }

    .fullScreenPlayerContainer .songDetailsContainer .songImage {
        @apply w-[15rem] h-[15rem] min-w-[15rem] min-h-[15rem];
    }

    .fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper {
        @apply flex justify-center items-center gap-12;
    }
}