*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.form-select.is-invalid {
  border-color: #dc3545;
}

.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.form-check-input.is-invalid {
  border-color: #dc3545;
}

.form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.input-group .form-control.is-invalid, .input-group .form-select.is-invalid {
  z-index: 2;
}

.input-group .form-control.is-invalid:focus, .input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn.active, .btn.active:focus {
  box-shadow: none;
}

.fade {
  transition: opacity .15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

.dropdown-menu {
  z-index: 1000;
}

.dropdown-item.active, .dropdown-item:active {
  color: #1f2937;
  background-color: #0d6efd;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.nav-tabs .nav-link {
  color: #4b5563;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #9ca3af;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #2563eb;
  border-color: #2563eb;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  color: #4b5563;
  box-shadow: none;
  background: #f3f4f6;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background: #2563eb;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
}

.nav-pills .disabled {
  color: #9ca3af;
  background-color: #f3f4f680;
}

.nav-pills.menu-sidebar .nav-link {
  box-shadow: none;
  background-color: #0000;
  border-radius: 0;
  padding: 0 5px;
}

.nav-pills.menu-sidebar .nav-link.active {
  color: #1266f1;
  border-left: .125rem solid #1266f1;
  font-weight: 600;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  -ms-flex-basis: 0;
  flex-basis: 0;
}

.tab-content > .active {
  display: block;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand .offcanvas {
  position: inherit;
  z-index: 1000;
  -ms-flex-grow: 1;
  background-color: #0000;
  border-left: 0;
  border-right: 0;
  flex-grow: 1;
  transition: none;
  bottom: 0;
  transform: none;
  visibility: visible !important;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active {
  color: #000000e6;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(.5rem - 1px);
  border-bottom-left-radius: calc(.5rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: .25;
}

.modal {
  z-index: 1055;
}

.modal-dialog {
  margin: .5rem;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  min-height: calc(100% - 1rem);
  display: flex;
}

.modal-backdrop {
  z-index: 1050;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header .btn-close {
  margin: -.5rem -.5rem -.5rem auto;
  padding: .5rem;
}

.modal-body {
  flex: auto;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.tooltip {
  z-index: 1080;
  font-family: var(--bs-font-sans-serif);
  -webkit-text-align: start;
  text-align: start;
  -webkit-text-shadow: none;
  text-shadow: none;
  -webkit-text-transform: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: -1px;
}

.tooltip-inner {
  color: #fff;
  -webkit-text-align: center;
  text-align: center;
  background-color: #6d6d6d;
  border-radius: .25rem;
  max-width: 200px;
  padding: 6px 16px;
  font-size: 14px;
}

.popover {
  z-index: 1070;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  -webkit-text-align: start;
  text-align: start;
  -webkit-text-shadow: none;
  text-shadow: none;
  -webkit-text-transform: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: .5rem;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  width: .5rem;
  height: 1rem;
  left: calc(-.5rem - 1px);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  content: "";
  border-bottom: 1px solid #f0f0f0;
  width: 1rem;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  width: .5rem;
  height: 1rem;
  right: calc(-.5rem - 1px);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  background-color: #fff;
  border-bottom: 1px solid #0003;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  margin-bottom: 0;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: 1rem;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-item {
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

.carousel-indicators {
  z-index: 2;
  margin-left: 15%;
  margin-right: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  -webkit-text-indent: -999px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.offcanvas {
  z-index: 1045;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas.show {
  transform: none;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.vr {
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  display: inline-block;
}

.animation {
  padding: auto;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fade-in {
  animation-name: _fade-in;
}

.fade-out {
  animation-name: _fade-out;
}

.animation.infinite {
  animation-iteration-count: infinite;
}

.animation.delay-1s {
  animation-delay: 1s;
}

.animation.delay-2s {
  animation-delay: 2s;
}

.animation.delay-3s {
  animation-delay: 3s;
}

.animation.delay-4s {
  animation-delay: 4s;
}

.animation.delay-5s {
  animation-delay: 5s;
}

.animation.fast {
  animation-duration: .8s;
}

.animation.faster {
  animation-duration: .5s;
}

.animation.slow {
  animation-duration: 2s;
}

.animation.slower {
  animation-duration: 3s;
}

.slide-in-left {
  animation-name: _slide-in-left;
}

.slide-in-right {
  animation-name: _slide-in-right;
}

.slide-out-left {
  animation-name: _slide-out-left;
}

.slide-out-right {
  animation-name: _slide-out-right;
}

.ripple-surface {
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ripple-surface-unbound {
  overflow: visible;
}

.ripple-wave {
  opacity: .5;
  pointer-events: none;
  touch-action: none;
  z-index: 999;
  background-image: radial-gradient(circle, #0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #0000 70%);
  border-radius: 50%;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0, 0, .15, 1), cubic-bezier(0, 0, .15, 1);
  position: absolute;
  transform: scale(0);
}

.ripple-wave.active {
  opacity: 0;
  transform: scale(1);
}

.btn .ripple-wave {
  background-image: radial-gradient(circle, #fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.ripple-surface-primary .ripple-wave {
  background-image: radial-gradient(circle, #1266f133 0, #1266f14d 40%, #1266f166 50%, #1266f180 60%, #1266f100 70%);
}

.ripple-surface-secondary .ripple-wave {
  background-image: radial-gradient(circle, #b23cfd33 0, #b23cfd4d 40%, #b23cfd66 50%, #b23cfd80 60%, #b23cfd00 70%);
}

.ripple-surface-success .ripple-wave {
  background-image: radial-gradient(circle, #00b74a33 0, #00b74a4d 40%, #00b74a66 50%, #00b74a80 60%, #00b74a00 70%);
}

.ripple-surface-info .ripple-wave {
  background-image: radial-gradient(circle, #39c0ed33 0, #39c0ed4d 40%, #39c0ed66 50%, #39c0ed80 60%, #39c0ed00 70%);
}

.ripple-surface-warning .ripple-wave {
  background-image: radial-gradient(circle, #ffa90033 0, #ffa9004d 40%, #ffa90066 50%, #ffa90080 60%, #ffa90000 70%);
}

.ripple-surface-danger .ripple-wave {
  background-image: radial-gradient(circle, #f9315433 0, #f931544d 40%, #f9315466 50%, #f9315480 60%, #f9315400 70%);
}

.ripple-surface-light .ripple-wave {
  background-image: radial-gradient(circle, #fbfbfb33 0, #fbfbfb4d 40%, #fbfbfb66 50%, #fbfbfb80 60%, #fbfbfb00 70%);
}

.ripple-surface-dark .ripple-wave {
  background-image: radial-gradient(circle, #26262633 0, #2626264d 40%, #26262666 50%, #26262680 60%, #26262600 70%);
}

.ripple-surface-white .ripple-wave {
  background-image: radial-gradient(circle, #fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.ripple-surface-black .ripple-wave {
  background-image: radial-gradient(circle, #0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #0000 70%);
}

.datepicker-toggle-button {
  background-color: #0000;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}

.datepicker-toggle-button:focus, .datepicker-toggle-button:hover {
  color: #2979ff;
}

.datepicker-backdrop {
  z-index: 1065;
  background-color: #0006;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.datepicker-dropdown-container {
  z-index: 1066;
  background-color: #fff;
  border-radius: .5rem;
  width: 328px;
  height: 380px;
  box-shadow: 0 10px 15px -3px #00000012, 0 4px 6px -2px #0000000d;
}

.datepicker-modal-container {
  z-index: 1066;
  background-color: #fff;
  border-radius: .6rem .6rem .5rem .5rem;
  flex-direction: column;
  width: 328px;
  height: 512px;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 15px -3px #00000012, 0 4px 6px -2px #0000000d;
}

.datepicker-header {
  background-color: #2979ff;
  border-radius: .5rem .5rem 0 0;
  flex-direction: column;
  height: 120px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.datepicker-title {
  flex-direction: column;
  justify-content: flex-end;
  height: 32px;
  display: flex;
}

.datepicker-title-text {
  -webkit-text-transform: uppercase;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
}

.datepicker-date {
  flex-direction: column;
  justify-content: flex-end;
  height: 72px;
  display: flex;
}

.datepicker-date-text {
  color: #fff;
  font-size: 34px;
  font-weight: 400;
}

.datepicker-main {
  height: 100%;
  position: relative;
}

.datepicker-date-controls {
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  color: #000000a3;
  justify-content: space-between;
  padding: 10px 12px 0;
  display: flex;
}

.datepicker-view-change-button {
  color: #666;
  box-shadow: none;
  background-color: #0000;
  border: none;
  border-radius: 10px;
  margin: 0;
  padding: 10px;
  font-size: .9rem;
  font-weight: 500;
}

.datepicker-view-change-button:hover, .datepicker-view-change-button:focus {
  background-color: #eee;
}

.datepicker-view-change-button:after {
  content: "";
  vertical-align: middle;
  border-top-style: solid;
  border-top-width: 5px;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  width: 0;
  height: 0;
  margin: 0 0 0 5px;
  display: inline-block;
}

.datepicker-arrow-controls {
  margin-top: 10px;
}

.datepicker-previous-button {
  color: #000000a3;
  background-color: #0000;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  margin: 0 24px 0 0;
  padding: 0;
  line-height: 40px;
  position: relative;
}

.datepicker-previous-button:hover, .datepicker-previous-button:focus {
  background-color: #eee;
  border-radius: 50%;
}

.datepicker-previous-button:after {
  content: "";
  border: 0 solid;
  border-width: 2px 0 0 2px;
  margin: 15.5px;
  position: absolute;
  inset: 0;
  transform: translateX(2px)rotate(-45deg);
}

.datepicker-next-button {
  color: #000000a3;
  background-color: #0000;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
  position: relative;
}

.datepicker-next-button:hover, .datepicker-next-button:focus {
  background-color: #eee;
  border-radius: 50%;
}

.datepicker-next-button:after {
  content: "";
  border: 2px solid;
  border-width: 2px 2px 0 0;
  margin: 15.5px;
  position: absolute;
  inset: 0;
  transform: translateX(-2px)rotate(45deg);
}

.datepicker-view {
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
}

.datepicker-table {
  width: 304px;
  margin-left: auto;
  margin-right: auto;
}

.datepicker-day-heading {
  -webkit-text-align: center;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
}

.datepicker-cell {
  -webkit-text-align: center;
  text-align: center;
}

.datepicker-cell.disabled {
  color: #ccc;
  cursor: default;
  pointer-events: none;
}

.datepicker-cell.disabled:hover {
  cursor: default;
}

.datepicker-cell:hover {
  cursor: pointer;
}

.datepicker-cell:not(.disabled):not(.selected):hover .datepicker-cell-content {
  background-color: #d3d3d3;
}

.datepicker-cell.selected .datepicker-cell-content {
  color: #fff;
  background-color: #2979ff;
}

.datepicker-cell:not(.selected).focused .datepicker-cell-content {
  background-color: #eee;
}

.datepicker-cell.focused .datepicker-cell-content.selected {
  background-color: #2979ff;
}

.datepicker-cell.current .datepicker-cell-content {
  border: 1px solid #000;
}

.datepicker-small-cell {
  width: 40px;
  height: 40px;
}

.datepicker-small-cell-content {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 13px;
  line-height: 36px;
}

.datepicker-large-cell {
  width: 76px;
  height: 42px;
}

.datepicker-large-cell-content {
  border-radius: 999px;
  width: 72px;
  height: 40px;
  padding: 1px 2px;
  line-height: 40px;
}

.datepicker-footer {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 56px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.datepicker-footer-btn {
  color: #2979ff;
  cursor: pointer;
  -webkit-text-transform: uppercase;
  text-transform: uppercase;
  letter-spacing: .1rem;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  height: 40px;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: .8rem;
  font-weight: 500;
  line-height: 40px;
}

.datepicker-footer-btn:hover, .datepicker-footer-btn:focus {
  background-color: #eee;
}

.datepicker-clear-btn {
  margin-right: auto;
}

.timepicker-wrapper {
  touch-action: none;
  z-index: 1065;
  opacity: 0;
  background-color: #0006;
  inset: 0;
}

.timepicker-elements {
  background: #fff;
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem;
  min-width: 310px;
  min-height: 325px;
}

.timepicker-head {
  background-color: #2979ff;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  height: 100px;
  padding: 10px 24px 10px 50px;
}

.timepicker-button {
  box-sizing: border-box;
  letter-spacing: .1rem;
  -webkit-text-transform: uppercase;
  text-transform: uppercase;
  color: #2979ff;
  background-color: #0000;
  border: none;
  border-radius: 10px;
  outline: none;
  min-width: 64px;
  height: 40px;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: .8rem;
  font-weight: 500;
  line-height: 40px;
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1), box-shadow .25s cubic-bezier(.4, 0, .2, 1), border .25s cubic-bezier(.4, 0, .2, 1);
}

.timepicker-button:hover {
  background-color: #00000014;
}

.timepicker-button:focus {
  background-color: #00000014;
  outline: none;
}

.timepicker-current {
  letter-spacing: -.00833em;
  color: #fff;
  opacity: .54;
  background: none;
  border: none;
  padding: 0;
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.timepicker-current.active {
  opacity: 1;
}

.timepicker-current-wrapper {
  direction: ltr;
}

.timepicker-mode-wrapper {
  color: #ffffff8a;
  margin-left: 20px;
  font-size: 18px;
}

.timepicker-mode-wrapper.active {
  opacity: 1;
}

.timepicker-clock-wrapper {
  min-width: 310px;
  max-width: 325px;
  height: 100%;
  min-height: 305px;
  overflow-x: hidden;
}

.timepicker-clock {
  cursor: default;
  background-color: #00000012;
  border-radius: 100%;
  width: 260px;
  height: 260px;
  margin: 0 auto;
  position: relative;
}

.timepicker-time-tips-minutes.active, .timepicker-time-tips-inner.active, .timepicker-time-tips-hours.active {
  color: #fff;
  background-color: #2979ff;
  font-weight: 400;
}

.timepicker-time-tips-minutes.disabled, .timepicker-time-tips-inner.disabled, .timepicker-time-tips-hours.disabled {
  color: #b3afaf;
  pointer-events: none;
  background-color: #0000;
}

.timepicker-dot {
  letter-spacing: -.00833em;
  color: #fff;
  opacity: .54;
  background: none;
  border: none;
  padding: 0;
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.timepicker-middle-dot {
  background-color: #2979ff;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timepicker-hand-pointer {
  transform-origin: center bottom 0;
  background-color: #2979ff;
  width: 2px;
  height: 40%;
  bottom: 50%;
  left: calc(50% - 1px);
}

.timepicker-time-tips.active {
  color: #fff;
}

.timepicker-circle {
  box-sizing: content-box;
  border: 14px solid #2979ff;
  border-radius: 100%;
  width: 4px;
  height: 4px;
  top: -21px;
  left: -15px;
}

.timepicker-hour-mode {
  color: #fff;
  opacity: .54;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
}

.timepicker-hour, .timepicker-minute {
  cursor: pointer;
}

.timepicker-hour-mode:hover, .timepicker-hour-mode:focus, .timepicker-hour:hover, .timepicker-hour:focus, .timepicker-minute:hover, .timepicker-minute:focus {
  background-color: #00000026;
  outline: none;
}

.timepicker-hour-mode.active, .timepicker-hour.active, .timepicker-minute.active {
  color: #fff;
  opacity: 1;
}

.timepicker-footer {
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  background-color: #fff;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.timepicker-container {
  max-height: calc(100% - 64px);
  overflow-y: auto;
  box-shadow: 0 10px 15px -3px #00000012, 0 4px 6px -2px #0000000d;
}

.timepicker-icon-up.active, .timepicker-icon-down.active {
  opacity: 1;
}

.timepicker-toggle-button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  transition: all .3s;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}

.timepicker-toggle-button:hover, .timepicker-toggle-button:focus, .timepicker-input:focus + .timepicker-toggle-button, .timepicker-input:focus + .timepicker-toggle-button i {
  color: #2979ff;
}

.timepicker a.timepicker-toggle-button, .timepicker-toggle-button.timepicker-icon {
  right: 1px;
}

.timepicker-modal .fade.show {
  opacity: 1;
}

.stepper {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: height .2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.stepper:not(.stepper-vertical) {
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  display: flex;
}

.stepper:not(.stepper-vertical) .stepper-content {
  width: 100%;
  padding: 1rem;
  position: absolute;
}

.stepper:not(.stepper-vertical) .stepper-step {
  flex: auto;
  height: 4.5rem;
}

.stepper:not(.stepper-vertical) .stepper-step:first-child .stepper-head {
  padding-left: 1.5rem;
}

.stepper:not(.stepper-vertical) .stepper-step:last-child .stepper-head {
  padding-right: 1.5rem;
}

.stepper:not(.stepper-vertical) .stepper-step:not(:first-child) .stepper-head:before {
  content: "";
  background-color: #0000001a;
  flex: 1;
  width: 100%;
  height: 1px;
  margin-right: .5rem;
}

.stepper:not(.stepper-vertical) .stepper-step:not(:last-child) .stepper-head:after {
  content: "";
  background-color: #0000001a;
  flex: 1;
  width: 100%;
  height: 1px;
  margin-left: .5rem;
}

.stepper:not(.stepper-vertical) .stepper-head-icon {
  margin: 1.5rem .5rem 1.5rem 0;
}

.stepper.stepper-mobile {
  justify-content: center;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.stepper.stepper-mobile.stepper-progress-bar .stepper-head-icon {
  display: none;
}

.stepper.stepper-mobile .stepper-step {
  -webkit-flex: unset;
  -ms-flex: unset;
  flex: unset;
  height: fit-content;
  margin: 1rem 0;
}

.stepper.stepper-mobile .stepper-step:not(:last-child) .stepper-head:after {
  margin-left: 0;
}

.stepper.stepper-mobile .stepper-step:not(:first-child) .stepper-head:before {
  margin-right: 0;
}

.stepper.stepper-mobile .stepper-step:not(:last-child):not(:first-child) .stepper-head {
  padding-left: .25rem;
  padding-right: .25rem;
}

.stepper.stepper-mobile .stepper-head-icon {
  z-index: 1;
  width: .5rem;
  height: .5rem;
  margin: 0;
  font-size: 0;
}

.stepper.stepper-mobile .stepper-head-text {
  display: none;
}

.stepper.stepper-mobile .stepper-content {
  top: 2.56rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }

  .form-switch .form-check-input {
    transition: none;
  }

  .form-range::-webkit-slider-thumb {
    transition: none;
  }

  .form-range::-moz-range-thumb {
    transition: none;
  }

  .form-floating > label, .fade, .collapsing, .collapsing.collapse-horizontal, .accordion-button:after, .modal.fade .modal-dialog, .carousel-item, .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end, .carousel-control-prev, .carousel-control-next, .carousel-indicators [data-bs-target] {
    transition: none;
  }

  .spinner-border, .spinner-grow {
    animation-duration: 1.5s;
  }
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler, .navbar-expand-sm .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-sm .offcanvas-top, .navbar-expand-sm .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-sm .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }

  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler, .navbar-expand-md .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-md .offcanvas-top, .navbar-expand-md .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-md .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-lg .offcanvas-top, .navbar-expand-lg .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-lg .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .modal-lg, .modal-xl {
    max-width: 800px;
  }

  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler, .navbar-expand-xl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xl .offcanvas-top, .navbar-expand-xl .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-xl .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .modal-xl {
    max-width: 1140px;
  }

  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler, .navbar-expand-xxl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xxl .offcanvas-top, .navbar-expand-xxl .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-xxl .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

@media (prefers-reduced-motion) {
  .animation {
    -webkit-animation: unset !important;
    animation: unset !important;
    transition: none !important;
  }
}

@media screen and (width >= 320px) and (width <= 820px) and (orientation: landscape) {
  .datepicker-modal-container .datepicker-header {
    height: 100%;
  }

  .datepicker-modal-container .datepicker-date {
    margin-top: 100px;
  }

  .datepicker-modal-container .datepicker-day-cell {
    width: 32x;
    height: 32x;
  }

  .datepicker-modal-container {
    flex-direction: row;
    width: 475px;
    height: 360px;
  }

  .datepicker-modal-container.datepicker-day-cell {
    width: 36px;
    height: 36px;
  }
}

@media screen and (width >= 320px) and (width <= 825px) and (orientation: landscape) {
  .timepicker-elements {
    border-bottom-left-radius: .5rem;
    min-width: auto;
    min-height: auto;
    overflow-y: auto;
    flex-direction: row !important;
  }

  .timepicker-head {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    height: auto;
    min-height: 305px;
    padding: 10px;
    padding-right: 10px !important;
  }

  .timepicker-head-content {
    flex-direction: column;
  }

  .timepicker-mode-wrapper {
    -webkit-box-pack: space-around !important;
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: space-around !important;
    flex-direction: row !important;
    justify-content: space-around !important;
  }

  .timepicker-current, .timepicker-dot {
    font-size: 3rem;
    font-weight: 400;
  }
}

@keyframes _spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes _fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes _fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes _fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _fade-in-left {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _fade-in-right {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _fade-out-down {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes _fade-out-left {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes _fade-out-right {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes _fade-out-up {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes _slide-in-down {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _slide-in-left {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _slide-in-right {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _slide-in-up {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _slide-out-down {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes _slide-out-left {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes _slide-out-right {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes _slide-out-up {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes _slide-down {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes _slide-left {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes _slide-right {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes _slide-up {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes _zoom-in {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes _zoom-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

@keyframes _tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  70% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes _pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes _show-up-clock {
  0% {
    opacity: 0;
    transform: scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.dark-container {
  mix-blend-mode: normal;
  background: linear-gradient(224.86deg, #07070740 2.75%, #11111140 96.04%);
  border: 1px solid #97979726;
  border-radius: 32px;
  width: 75%;
  padding: 3rem 5rem;
  position: relative;
  box-shadow: 2px 2px 6px 3px #0000002e;
}

.galaxy-container {
  mix-blend-mode: normal;
  background: linear-gradient(224.86deg, #48484840 2.75%, #11111147 96.04%);
  border-radius: 57px;
  width: 75%;
  padding: 0 5em;
  box-shadow: 2px 2px 6px 3px #000000c9;
}

.nft-button-main {
  cursor: pointer;
  background-size: 200% 200%;
  border: none;
  border-radius: 35px;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  padding: 0;
  font-family: MavenPro;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  box-shadow: 0 0 12px #00000080;
}

.nft-button-circle {
  pointer-events: auto;
  cursor: pointer;
  vertical-align: middle;
  color: #ff0057;
  border: 1px solid #97979723;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  box-shadow: 0 0 6px #00000080;
  background: linear-gradient(131.33deg, #272727 27.19%, #191919 114.82%) !important;
}

.nft-button-file {
  cursor: pointer;
  appearance: none;
  text-align: left;
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  background: linear-gradient(52.69deg, #272727 3.93%, #191919 116.96%);
  border: 1px solid #97979723;
  border-radius: 30px;
  outline: 0;
  margin: 0 0 1rem;
  padding: .8rem 1rem;
  font-family: StyreneA-Regular;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 12px #00000080;
}

.nft-button-tier {
  cursor: pointer;
  background: linear-gradient(52.69deg, #893fe4 3.93%, #be3d7a 116.96%);
  border: none;
  border-radius: 35px;
  margin-top: 50px;
  margin-bottom: 24px;
  padding: 0;
  font-size: 22px;
  font-weight: 700;
  box-shadow: 0 0 12px #00000080;
  font-family: MavenPro !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.collapse {
  visibility: collapse !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

.inset-0 {
  inset: 0 !important;
}

.inset-y-0 {
  top: 0 !important;
  bottom: 0 !important;
}

.-left-1 {
  left: -.25rem !important;
}

.-top-1 {
  top: -.25rem !important;
}

.bottom-8 {
  bottom: 2rem !important;
}

.bottom-full {
  bottom: 100% !important;
}

.left-0 {
  left: 0 !important;
}

.left-1\/2 {
  left: 50% !important;
}

.left-3 {
  left: .75rem !important;
}

.left-4 {
  left: 1rem !important;
}

.right-0 {
  right: 0 !important;
}

.right-3 {
  right: .75rem !important;
}

.right-4 {
  right: 1rem !important;
}

.right-8 {
  right: 2rem !important;
}

.top-0 {
  top: 0 !important;
}

.top-1\/2 {
  top: 50% !important;
}

.top-full {
  top: 100% !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-50 {
  z-index: 50 !important;
}

.col-span-2 {
  grid-column: span 2 / span 2 !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.-ml-1 {
  margin-left: -.25rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: .75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.ml-12 {
  margin-left: 3rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.ml-3 {
  margin-left: .75rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mr-3 {
  margin-right: .75rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mr-\[15px\] {
  margin-right: 15px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mt-3 {
  margin-top: .75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-\[0\.5rem\] {
  margin-top: .5rem !important;
}

.mt-\[1rem\] {
  margin-top: 1rem !important;
}

.box-border {
  box-sizing: border-box !important;
}

.line-clamp-1 {
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
  overflow: hidden !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.grid {
  display: grid !important;
}

.contents {
  display: contents !important;
}

.list-item {
  display: list-item !important;
}

.hidden {
  display: none !important;
}

.h-0 {
  height: 0 !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-11 {
  height: 2.75rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-2 {
  height: .5rem !important;
}

.h-2\.5 {
  height: .625rem !important;
}

.h-24 {
  height: 6rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-60 {
  height: 15rem !important;
}

.h-7 {
  height: 1.75rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-96 {
  height: 24rem !important;
}

.h-\[1\.4rem\] {
  height: 1.4rem !important;
}

.h-\[1\.6rem\] {
  height: 1.6rem !important;
}

.h-\[1\.7rem\] {
  height: 1.7rem !important;
}

.h-\[107\%\] {
  height: 107% !important;
}

.h-\[250px\] {
  height: 250px !important;
}

.h-\[40px\] {
  height: 40px !important;
}

.h-\[50\%\] {
  height: 50% !important;
}

.h-full {
  height: 100% !important;
}

.max-h-96 {
  max-height: 24rem !important;
}

.min-h-\[150px\] {
  min-height: 150px !important;
}

.min-h-\[200px\] {
  min-height: 200px !important;
}

.min-h-max {
  min-height: max-content !important;
}

.w-0 {
  width: 0 !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-12 {
  width: 3rem !important;
}

.w-14 {
  width: 3.5rem !important;
}

.w-20 {
  width: 5rem !important;
}

.w-24 {
  width: 6rem !important;
}

.w-28 {
  width: 7rem !important;
}

.w-32 {
  width: 8rem !important;
}

.w-40 {
  width: 10rem !important;
}

.w-44 {
  width: 11rem !important;
}

.w-48 {
  width: 12rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-60 {
  width: 15rem !important;
}

.w-7 {
  width: 1.75rem !important;
}

.w-72 {
  width: 18rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-8\/12 {
  width: 66.6667% !important;
}

.w-80 {
  width: 20rem !important;
}

.w-\[1\.4rem\] {
  width: 1.4rem !important;
}

.w-\[1\.6rem\] {
  width: 1.6rem !important;
}

.w-\[1\.7rem\] {
  width: 1.7rem !important;
}

.w-\[101\%\] {
  width: 101% !important;
}

.w-\[40px\] {
  width: 40px !important;
}

.w-full {
  width: 100% !important;
}

.min-w-8 {
  min-width: 2rem !important;
}

.min-w-\[200px\] {
  min-width: 200px !important;
}

.min-w-\[250px\] {
  min-width: 250px !important;
}

.min-w-\[40px\] {
  min-width: 40px !important;
}

.min-w-full {
  min-width: 100% !important;
}

.\!max-w-2xl {
  max-width: 42rem !important;
}

.flex-1 {
  flex: 1 !important;
}

.shrink-0 {
  flex-shrink: 0 !important;
}

.grow {
  flex-grow: 1 !important;
}

.table-auto {
  table-layout: auto !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.border-collapse {
  border-collapse: collapse !important;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform-none {
  transform: none !important;
}

@keyframes backgroundRotate {
  0%, 100% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }
}

.animate-backgroundRotate {
  animation: 3s infinite backgroundRotate !important;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-progress {
  cursor: progress !important;
}

.select-none {
  user-select: none !important;
}

.resize {
  resize: both !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.content-center {
  align-content: center !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: .75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
}

.divide-y-\[3rem\] > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(3rem * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(3rem * var(--tw-divide-y-reverse)) !important;
}

.divide-neutral-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(64 64 64 / var(--tw-divide-opacity, 1)) !important;
}

.divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-divide-opacity, 1)) !important;
}

.self-center {
  align-self: center !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.truncate {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.rounded-\[2\.5rem\] {
  border-radius: 2.5rem !important;
}

.rounded-\[25px\] {
  border-radius: 25px !important;
}

.rounded-\[2rem\] {
  border-radius: 2rem !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-lg {
  border-radius: .5rem !important;
}

.rounded-md {
  border-radius: .375rem !important;
}

.rounded-xl {
  border-radius: .75rem !important;
}

.rounded-b-\[0px\] {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-l-lg {
  border-top-left-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.rounded-r-lg {
  border-top-right-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.rounded-t-\[0px\] {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border, .border-\[1px\] {
  border-width: 1px !important;
}

.border-y {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.border-b {
  border-bottom-width: 1px !important;
}

.border-b-0 {
  border-bottom-width: 0 !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.border-l-8 {
  border-left-width: 8px !important;
}

.border-r-8 {
  border-right-width: 8px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-t-8 {
  border-top-width: 8px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-\[\#ff3377\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 51 119 / var(--tw-border-opacity, 1)) !important;
}

.border-gray-200\/10 {
  border-color: #e5e7eb1a !important;
}

.border-gray-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1)) !important;
}

.border-slate-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(241 245 249 / var(--tw-border-opacity, 1)) !important;
}

.border-slate-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(71 85 105 / var(--tw-border-opacity, 1)) !important;
}

.border-slate-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(51 65 85 / var(--tw-border-opacity, 1)) !important;
}

.border-transparent {
  border-color: #0000 !important;
}

.border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1)) !important;
}

.border-t-\[\#323232\] {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(50 50 50 / var(--tw-border-opacity, 1)) !important;
}

.border-t-gray-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(17 24 39 / var(--tw-border-opacity, 1)) !important;
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#151515\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#181818\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#1d1d1d\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 29 29 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#272626\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(39 38 38 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#343434\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(52 52 52 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#FF0057\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 87 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#efefef\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity, 1)) !important;
}

.bg-\[\#ff0057\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 87 / var(--tw-bg-opacity, 1)) !important;
}

.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1)) !important;
}

.bg-black\/50 {
  background-color: #00000080 !important;
}

.bg-blue-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity, 1)) !important;
}

.bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1)) !important;
}

.bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1)) !important;
}

.bg-gray-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1)) !important;
}

.bg-gray-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1)) !important;
}

.bg-gray-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1)) !important;
}

.bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1)) !important;
}

.bg-inherit {
  background-color: inherit !important;
}

.bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1)) !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) !important;
}

.bg-zinc-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity, 1)) !important;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
}

.from-\[\#272727\] {
  --tw-gradient-from: #272727 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #27272700 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.from-\[\#3FE4CE\] {
  --tw-gradient-from: #3fe4ce var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #3fe4ce00 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.from-\[\#893FE4\] {
  --tw-gradient-from: #893fe4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #893fe400 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.from-\[\#FF0057\] {
  --tw-gradient-from: #ff0057 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #ff005700 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.from-green-600 {
  --tw-gradient-from: #16a34a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #16a34a00 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.from-indigo-500 {
  --tw-gradient-from: #6366f1 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.to-\[\#191919\] {
  --tw-gradient-to: #191919 var(--tw-gradient-to-position) !important;
}

.to-\[\#67BE3D\] {
  --tw-gradient-to: #67be3d var(--tw-gradient-to-position) !important;
}

.to-\[\#A40038\] {
  --tw-gradient-to: #a40038 var(--tw-gradient-to-position) !important;
}

.to-\[\#BE3D7A\] {
  --tw-gradient-to: #be3d7a var(--tw-gradient-to-position) !important;
}

.to-green-300 {
  --tw-gradient-to: #86efac var(--tw-gradient-to-position) !important;
}

.fill-current {
  fill: currentColor !important;
}

.object-cover {
  object-fit: cover !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1\.5 {
  padding: .375rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-\[0\.1rem\] {
  padding-left: .1rem !important;
  padding-right: .1rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-2\.5 {
  padding-top: .625rem !important;
  padding-bottom: .625rem !important;
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-\[0\.5rem\] {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-\[1rem\] {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-3 {
  padding-bottom: .75rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pl-7 {
  padding-left: 1.75rem !important;
}

.pl-\[11px\] {
  padding-left: 11px !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pr-3 {
  padding-right: .75rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pr-8 {
  padding-right: 2rem !important;
}

.pr-\[0\.5\] {
  padding-right: .5px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-sub {
  vertical-align: sub !important;
}

.font-\[\'MavenPro\'\] {
  font-family: MavenPro !important;
}

.font-\[\'StyreneA-Regular\'\,_sans-serif\] {
  font-family: StyreneA-Regular, sans-serif !important;
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-\[\.9rem\] {
  font-size: .9rem !important;
}

.text-\[0\.8rem\] {
  font-size: .8rem !important;
}

.text-\[1\.35rem\] {
  font-size: 1.35rem !important;
}

.text-\[1\.7rem\] {
  font-size: 1.7rem !important;
}

.text-\[1\.8rem\] {
  font-size: 1.8rem !important;
}

.text-\[10\.5px\] {
  font-size: 10.5px !important;
}

.text-\[12px\] {
  font-size: 12px !important;
}

.text-\[14px\] {
  font-size: 14px !important;
}

.text-\[18px\] {
  font-size: 18px !important;
}

.text-\[1rem\] {
  font-size: 1rem !important;
}

.text-\[20px\] {
  font-size: 20px !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-xs {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.italic {
  font-style: italic !important;
}

.\!leading-10 {
  line-height: 2.5rem !important;
}

.\!leading-\[5rem\] {
  line-height: 5rem !important;
}

.leading-10 {
  line-height: 2.5rem !important;
}

.leading-5 {
  line-height: 1.25rem !important;
}

.leading-6 {
  line-height: 1.5rem !important;
}

.leading-\[3rem\] {
  line-height: 3rem !important;
}

.leading-\[5rem\] {
  line-height: 5rem !important;
}

.leading-loose {
  line-height: 2 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.\!text-\[\#7c7c7c\] {
  --tw-text-opacity: 1 !important;
  color: rgb(124 124 124 / var(--tw-text-opacity, 1)) !important;
}

.\!text-\[\#ff0057\] {
  --tw-text-opacity: 1 !important;
  color: rgb(255 0 87 / var(--tw-text-opacity, 1)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#12a0e8\] {
  --tw-text-opacity: 1 !important;
  color: rgb(18 160 232 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#28B4AF\] {
  --tw-text-opacity: 1 !important;
  color: rgb(40 180 175 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#3FE37A\] {
  --tw-text-opacity: 1 !important;
  color: rgb(63 227 122 / var(--tw-text-opacity, 1)) !important;
}

.text-\[\#FF0057\], .text-\[\#ff0057\] {
  --tw-text-opacity: 1 !important;
  color: rgb(255 0 87 / var(--tw-text-opacity, 1)) !important;
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1)) !important;
}

.text-blue-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1)) !important;
}

.text-gray-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1)) !important;
}

.text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1)) !important;
}

.text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1)) !important;
}

.text-gray-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(249 250 251 / var(--tw-text-opacity, 1)) !important;
}

.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1)) !important;
}

.text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1)) !important;
}

.text-green-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(74 222 128 / var(--tw-text-opacity, 1)) !important;
}

.text-green-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1)) !important;
}

.text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1)) !important;
}

.text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1)) !important;
}

.text-slate-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(226 232 240 / var(--tw-text-opacity, 1)) !important;
}

.text-slate-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1)) !important;
}

.text-slate-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(248 250 252 / var(--tw-text-opacity, 1)) !important;
}

.text-slate-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.text-yellow-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1)) !important;
}

.text-zinc-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(161 161 170 / var(--tw-text-opacity, 1)) !important;
}

.underline {
  text-decoration-line: underline !important;
}

.no-underline {
  text-decoration-line: none !important;
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1)) !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.mix-blend-screen {
  mix-blend-mode: screen !important;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.outline-none {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.outline {
  outline-style: solid !important;
}

.blur {
  --tw-blur: blur(8px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.blur-sm {
  --tw-blur: blur(4px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.grayscale {
  --tw-grayscale: grayscale(100%) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px) !important;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition-all {
  transition-property: all !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition-opacity {
  transition-property: opacity !important;
  transition-duration: .15s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.duration-300 {
  transition-duration: .3s !important;
}

.duration-500 {
  transition-duration: .5s !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

#solidAppRoot {
  width: inherit;
  height: inherit;
  max-height: inherit;
  scrollbar-width: auto;
  scrollbar-gutter: stable;
  overflow: hidden;
}

#solidAppRoot #bgWrapper {
  width: inherit;
  height: inherit;
  max-height: inherit;
  background-color: #222;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

#solidAppRoot #bgWrapper .pageParentContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  min-height: 0;
  width: inherit;
  height: inherit;
  max-height: inherit;
  overscroll-behavior: contain;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

#bgWrapper .pageParentContainer .solidPageContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior: contain;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: relative;
  container: solidPageContainer / inline-size;
  background-color: #111 !important;
}

@supports ((-moz-appearance: none)) {
  #bgWrapper .pageParentContainer .solidPageContainer {
    width: -moz-available;
    height: inherit;
    max-height: inherit;
    scrollbar-color: #242424 #111;
    scrollbar-width: thin;
  }
}

@supports ((-webkit-appearance: none)) {
  #bgWrapper .pageParentContainer .solidPageContainer {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
}

#bgWrapper .pageParentContainer .solidPageContainer::-webkit-scrollbar {
  display: none;
}

#bgWrapper .pageParentContainer .solidPageContainer .singlePageWrapper {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  touch-action: pan-y;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  overflow-y: scroll;
}

@supports ((-moz-appearance: none)) {
  #bgWrapper .pageParentContainer .solidPageContainer .singlePageWrapper {
    width: -moz-available;
    height: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  #bgWrapper .pageParentContainer .solidPageContainer .singlePageWrapper {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
  }
}

@supports ((-moz-appearance: none)) {
  .height-fill-available {
    height: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .height-fill-available {
    height: -webkit-fill-available;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-button {
  display: none !important;
}

::-webkit-scrollbar-track {
  background-color: #111;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  height: 50px;
  background-color: #242424 !important;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select, input {
  padding: initial;
  font-size: initial;
}

textarea {
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus, input:focus, [type="checkbox"]:focus, [type="radio"]:focus {
  box-shadow: none !important;
}

.checkboxWrapper {
  min-height: 1.5rem;
  margin-top: 1.5rem;
  display: block;
}

.checkboxWrapper label {
  cursor: pointer;
  align-items: center;
  display: inline-flex;
}

.checkboxWrapper label input {
  cursor: pointer;
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
}

.checkboxWrapper label span {
  margin-left: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.checkboxWrapper label span a {
  --tw-text-opacity: 1;
  color: rgb(157 157 157 / var(--tw-text-opacity, 1));
}

.musician_question_text .checkboxWrapper {
  margin-top: 1rem;
}

.dark-container label span {
  color: #9b9b9b;
  font-size: 13px;
}

.dark-container h3 img {
  vertical-align: text-bottom;
  margin-right: 16px;
}

table.priceTable {
  border-collapse: collapse;
  background-color: #121212;
  border-style: hidden;
  border-radius: 30px;
  width: 100%;
  margin: 32px 0 50px;
  font-family: StyreneA-Regular;
  box-shadow: 0 0 0 1px #313131;
}

table.priceTable td, table.priceTable th {
  border-bottom: 1px solid #323232;
  padding: 10px;
}

table.priceTable tbody td {
  color: #fff;
  font-size: 14px;
}

table.priceTable thead {
  border-bottom: 2px solid #323232;
}

table.priceTable thead th {
  color: #fff;
  font-weight: bold;
}

.nftId span {
  color: #ff0057;
  cursor: pointer;
  background: linear-gradient(131.33deg, #272727 27.19%, #191919 114.82%);
  border: 1px solid #97979723;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 0 0 12px #00000080;
}

.nftId span i {
  vertical-align: bottom;
}

.nftSelect {
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
  position: relative;
}

.nftSelect select {
  cursor: pointer;
  color: #fff;
  appearance: none;
  background: linear-gradient(52.69deg, #272727 3.93%, #191919 116.96%);
  border: 1px solid #97979723;
  border-radius: 30px;
  outline: 0;
  width: 100%;
  padding: .8rem 1rem;
  font-family: StyreneA-Regular;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  display: inline-block;
  box-shadow: 0 0 12px #00000080;
}

.nftSelect select::-ms-expand {
  display: none;
}

.nftSelect select:hover, .nftSelect select:focus, .nftSelect button:hover, .nftSelect button:focus {
  color: #fff;
  background: #4c4c4c;
}

.nftSelect select:disabled {
  opacity: .5;
  pointer-events: none;
}

.select_arrow {
  pointer-events: none;
  border: 4px solid #ff0057;
  border-width: 0 4px 4px 0;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 23%;
  right: 24px;
  transform: rotate(45deg);
}

.nftSelect select:hover ~ .select_arrow, .nftSelect select:focus ~ .select_arrow {
  border-color: #ff0057;
}

.nftSelect select:disabled ~ .select_arrow {
  border-top-color: #ccc;
}

.sliderRadio label, input[type="radio"] + label {
  padding: 10px 20px !important;
}

.sliderRadio {
  background: #111;
  border-radius: 25px;
  margin-top: 32px;
  padding: 4px;
  display: inline-block;
  position: relative;
  box-shadow: 0 -4px 4px #ffffff0c, 0 0 4px 2px #00000052, inset 0 -4px 5px #7b7b7b1b, inset 0 4px 5px 5px #00000061;
}

.sliderRadio input[type="radio"] {
  display: none;
}

.sliderRadio input[type="radio"]:checked + label {
  z-index: 100 !important;
  position: relative !important;
}

.ignoreCss, .ignoreCss:checked + label {
  all: unset;
}

.ignoreCss input[type="radio"]:not([disabled]) + label {
  background-image: none;
}

.sliderRadio label {
  text-align: center;
  cursor: pointer;
  opacity: .5;
  width: 245px;
  font-family: StyreneA-Regular;
  font-size: 1.125rem;
  font-weight: 700;
  display: inline-block;
  margin: 0 !important;
}

.sliderRadio .slidingPiece, .sliderRadio input[type="radio"]:checked + label {
  color: #fff;
  opacity: 1;
  background-color: #77abff;
  border-radius: 30px;
  transition-timing-function: cubic-bezier(.74, .14, .38, .99);
  box-shadow: 0 0 5px #00000080;
  background: linear-gradient(90deg, #ff0057 7.13%, #a40038 91.09%) !important;
}

.primary-background {
  background: #111;
}

.primary-color {
  color: #ff0057 !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.background-none {
  background: none !important;
}

.backButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(54 54 54 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  width: 3rem;
  height: 3rem;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
  opacity: .75;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 10px;
  padding: .25rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  z-index: 10 !important;
}

.backButton:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  color: #111827bf;
  opacity: 1;
}

.backButton:disabled {
  cursor: not-allowed;
  opacity: .25;
}

.backButton svg {
  width: 24px;
  height: 24px;
}

.mainContainer {
  height: inherit;
  max-height: inherit;
  padding-left: 0;
  padding-right: 0;
  transition: all .5s;
}

@supports ((-moz-appearance: none)) {
  .mainContainer {
    width: -moz-available;
    max-width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .mainContainer {
    width: -webkit-fill-available;
    max-width: -webkit-fill-available;
  }
}

.masonryListingsContainer {
  padding-left: 2rem;
  padding-right: 2rem;
  transition: width .5s;
}

.masonryListingsContainer .backButton {
  margin-left: 0;
}

.solid-load-indicator-centered {
  position: absolute;
  top: 47%;
  left: 47%;
}

@media (width <= 640px) {
  .masonryListingsContainer {
    padding-top: 2rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  @supports ((-moz-appearance: none)) {
    .masonryListingsContainer {
      width: -moz-available !important;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .masonryListingsContainer {
      width: -webkit-fill-available !important;
    }
  }
}

.artist-meta-text {
  word-break: break-all;
  white-space: normal !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
}

.nft-coming-soon {
  vertical-align: middle;
  background: linear-gradient(145deg, #7732db, #0f0b13);
  border-radius: 50px;
  padding: 7px;
  font-size: 13px;
  line-height: 1;
}

.loading-page-indicator {
  z-index: 10000000;
  display: none;
  position: absolute;
  top: 47%;
  left: 47%;
}

#primary-content > .loading-indicator {
  margin-left: 48%;
}

.disabledLink {
  pointer-events: none;
}

.collapse.show {
  visibility: unset !important;
}

.embla {
  --slide-height: 500px;
  --slide-spacing: 1rem;
  --slide-size: 85%;
  margin: auto;
}

@supports ((-moz-appearance: none)) {
  .embla {
    max-width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .embla {
    max-width: -webkit-fill-available;
  }
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  display: flex;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  min-height: 400px;
  max-height: 400px;
  transform: translate3d(0, 0, 0);
}

.embla__slide__inner {
  object-fit: cover;
  height: 100% !important;
}

.embla__slide .imageWraper, .embla__slide .videoWraper {
  position: relative;
  width: 100% !important;
  height: 100% !important;
}

.embla__slide img {
  cursor: pointer;
}

.embla__slide img, .embla__slide video {
  object-fit: cover;
  border-radius: 1.5rem;
  position: absolute;
  inset: 0;
  width: 100% !important;
  height: 100% !important;
}

.embla__slide .videoWraper .expandIcon {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.embla__slide__number {
  box-shadow: inset 0 0 0 .2rem var(--detail-medium-contrast);
  height: var(--slide-height);
  user-select: none;
  border-radius: 1.8rem;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
}

.embla__controls {
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
  display: grid;
}

.embla__buttons {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: .6rem;
  display: grid;
}

.embla__button {
  -webkit-tap-highlight-color: #f5f5f5;
  appearance: none;
  touch-action: manipulation;
  cursor: pointer;
  z-index: 1;
  color: #cbcbcb;
  background-color: #0000;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
  box-shadow: inset 0 0 0 .2rem #111;
}

.embla__button:disabled {
  color: #424243;
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}

.embla__dots {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: .25rem;
  margin-right: -.6rem;
  display: flex;
}

.embla__dot {
  -webkit-tap-highlight-color: #f5f5f5;
  appearance: none;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.embla__dot:after {
  content: "";
  border-radius: 50%;
  align-items: center;
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  box-shadow: inset 0 0 0 .1rem #cbcbcb;
}

.embla__dot--selected {
  background-color: #cbcbcb;
}

.imageViewer > .container {
  position: unset !important;
}

.enableBlur > * {
  filter: blur(1.2px);
}

.disableBlur {
  filter: blur();
}

.dont-show {
  display: none;
}

.show {
  display: block;
}

.modalContainer {
  transition: opacity .15s linear;
}

.modalContainer:not(.show) {
  opacity: 0;
}

.modal.modalContainer .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

.modal-backdrop.modalContainer, .offcanvas-backdrop.modalContainer {
  opacity: 0;
}

.timepicker-modal .modalContainer.show {
  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  .modalContainer, .modal.modalContainer .modal-dialog, .modalContainer, .modal.modalContainer .modal-dialog {
    transition: none;
  }
}

.modalContainer {
  outline-offset: 2px;
  z-index: 2147483647;
  outline: 2px solid #0000;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  overflow: hidden auto;
}

.modalContainer .modalDialog {
  pointer-events: none;
  width: auto;
}

.modalContainer .modal-content {
  pointer-events: auto;
  cursor: auto;
  --tw-bg-opacity: 1;
  background-color: rgb(44 44 44 / var(--tw-bg-opacity, 1));
  color: currentColor;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  z-index: 2147483647;
  border-style: none;
  border-radius: 1.5rem;
  outline: 2px solid #0000;
  flex-direction: column;
  display: flex;
}

.modalContainer .modalHeader {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modalContainer .closeIcon {
  box-sizing: content-box;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  opacity: .5;
  border-style: none;
  border-radius: 0;
  padding: .25rem;
  font-size: 20px;
}

.modalContainer .closeIcon:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  opacity: .75;
  text-decoration-line: none;
}

.modalContainer .closeIcon:focus {
  opacity: 1;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.modalContainer .modalTitle {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
}

.modalContainer.kycPromptModalContainer .modalTitle {
  text-align: left;
}

.modalContainer .modalSubTitle {
  font-size: 15px;
}

.modalContainer .modalBody {
  padding: 1rem 3rem;
  position: relative;
}

.modalContainer .modalBody p {
  line-height: 1.75rem;
}

.modalContainer.kycPromptModalContainer .modalBody {
  padding-top: 0;
  padding-bottom: 0;
}

.modalContainer.kycPromptModalContainer .modalDescription {
  text-align: left;
  line-height: 2rem;
}

.modalContainer .modalFooter {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.modalContainer .modal-bottom-buttons {
  justify-content: space-evenly !important;
}

.modalContainer.kycPromptModalContainer .modalFooter {
  gap: 1rem;
  padding: 0 2rem 3rem;
}

.modalContainer .closeButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(20 20 20 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  padding: 1rem 2.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.modalContainer .closeButton:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modalContainer .closeButton:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.modalContainer .closeButton:active {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modalContainer .deleteButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(214 5 67 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  padding: 1rem 2.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.modalContainer .deleteButton:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(198 1 59 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modalContainer .deleteButton:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(198 1 59 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.modalContainer .deleteButton:active {
  --tw-bg-opacity: 1;
  background-color: rgb(198 1 59 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modalContainer .stopButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(214 5 67 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  padding: 1rem 2.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.modalContainer .stopButton:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(198 1 59 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modalContainer .stopButton:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(198 1 59 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.modalContainer .stopButton:active {
  --tw-bg-opacity: 1;
  background-color: rgb(198 1 59 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modalContainer .modalBody form input {
  box-sizing: border-box;
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  width: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  margin-top: .75rem;
  padding: 1rem 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

.modalContainer .modalBody form input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity, 1));
}

.modalContainer .modalBody form input {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.modalContainer .modalBody form input:focus {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.modalContainer .modalBody form input {
  border: 1px solid #0000;
}

.modal.modalContainer .modal-dialog {
  z-index: 2147483647;
  transform: none;
  min-height: 0 !important;
}

.modal-backdrop {
  z-index: 2;
}

.streams-logo {
  justify-content: end;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.streams-logo .promoted-logo {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #14532d var(--tw-gradient-from-position);
  --tw-gradient-to: #16a34a var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  text-transform: uppercase;
  z-index: 1;
  color: #fff;
  border-radius: 8px;
  max-width: min-content;
  margin-right: 5px;
  padding: 7px;
  font-size: 7px;
}

@media (width <= 640px) {
  .streams-logo .promoted-logo {
    left: 60%;
  }
}

.streams-logo .free-logo {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #134e4a var(--tw-gradient-from-position);
  --tw-gradient-to: #0d9488 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  text-transform: uppercase;
  z-index: 1;
  color: #fff;
  border-radius: 8px;
  max-width: min-content;
  margin-right: 5px;
  padding: 7px;
  font-size: 7px;
}

@media (width <= 640px) {
  .streams-logo .free-logo {
    left: 77%;
  }
}

.streams-logo .nft-logo {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #312e81 var(--tw-gradient-from-position);
  --tw-gradient-to: #4f46e5 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.tooltipDiv {
  display: inline-block;
  position: relative;
}

.tooltipDiv .tooltipText {
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  padding: .25rem .75rem;
  font-size: .75rem;
  line-height: 1rem;
  display: none;
  position: absolute;
  bottom: 100%;
  right: 0;
}

.tooltipDiv:hover .tooltipText {
  display: block;
}

.grecaptcha-badge {
  opacity: 0;
  height: 0 !important;
}

.grecaptcha-logo {
  visibility: hidden;
}

.v3-captcha-box {
  margin-top: 20px;
}

.v3-captcha-box captcha {
  border-style: none;
}

.v3-captcha-box .custom-captcha._cc-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(27 26 26 / var(--tw-bg-opacity, 1));
  border-style: none;
}

.v3-captcha-box .custom-captcha:not(._cc-loading):not(._cc-loaded) ._cc-checkmark {
  border-style: none;
}

section.src-containers--container {
  background-color: #0f0b13;
}

#suspicious {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#suspicious > button {
  color: #aaa;
  cursor: pointer;
  background-color: #333;
  border-radius: 9999px;
  width: max-content;
  margin-top: 10px;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1;
}

@keyframes rainbowColors {
  0% {
    color: red;
  }

  16.666% {
    color: orange;
  }

  33.333% {
    color: #ff0;
  }

  50% {
    color: green;
  }

  66.666% {
    color: #00f;
  }

  83.333% {
    color: indigo;
  }

  100% {
    color: violet;
  }
}

@keyframes rainbowStroke {
  0% {
    stroke: red;
  }

  16.666% {
    stroke: orange;
  }

  33.333% {
    stroke: #ff0;
  }

  50% {
    stroke: green;
  }

  66.666% {
    stroke: #00f;
  }

  83.333% {
    stroke: indigo;
  }

  100% {
    stroke: violet;
  }
}

@keyframes rainbowFill {
  0% {
    fill: red;
  }

  16.666% {
    fill: orange;
  }

  33.333% {
    fill: #ff0;
  }

  50% {
    fill: green;
  }

  66.666% {
    fill: #00f;
  }

  83.333% {
    fill: indigo;
  }

  100% {
    fill: violet;
  }
}

.rainbowText {
  animation: 6s infinite rainbowColors;
}

.rainbowSvg svg {
  width: 100%;
  height: 100%;
}

.rainbowSvg svg path {
  animation: 6s infinite rainbowStroke;
}

.sb-content {
  width: 450px !important;
  max-width: 450px !important;
  position: absolute !important;
  right: 0 !important;
}

.bottomsheet-dropup-container {
  scrollbar-width: none;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  overflow-y: scroll;
}

@supports ((-moz-appearance: none)) {
  .bottomsheet-dropup-container {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .bottomsheet-dropup-container {
    width: -webkit-fill-available;
  }
}

.sb-handle-container {
  padding-top: 14px !important;
  padding-bottom: 5px !important;
}

.bottomsheet-dropup-container .dropupTopBar {
  justify-content: flex-end;
  align-items: center;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .bottomsheet-dropup-container .dropupTopBar {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .bottomsheet-dropup-container .dropupTopBar {
    width: -webkit-fill-available;
  }
}

.bottomsheet-dropup-container .dropupTopBar .dropupCloseIcon {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  align-self: flex-end;
  font-size: 1.5rem;
  line-height: 2rem;
}

.bottomsheet-dropup-container .dropup-inner {
  height: inherit;
  width: inherit;
}

.bottomsheet-dropup-container .dropupTitleDiv {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .bottomsheet-dropup-container .dropupTitleDiv {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .bottomsheet-dropup-container .dropupTitleDiv {
    width: -webkit-fill-available;
  }
}

.bottomsheet-dropup-container .dropupTitleDiv .dropupTitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 20px;
  line-height: 2.5rem;
}

.bottomsheet-dropup-container .dropupTitleDiv .dropupSubtitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 12px;
}

.bottomsheet-dropup-container .item {
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .bottomsheet-dropup-container .item {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .bottomsheet-dropup-container .item {
    width: -webkit-fill-available;
  }
}

.bottomsheet-dropup-container .item i, .bottomsheet-dropup-container .item svg {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 18px;
}

.bottomsheet-dropup-container .item span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 16px;
}

.bottomsheet-dropup-container .item .spinner {
  margin-right: 0;
}

.description {
  resize: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-item {
  opacity: 0;
  transition: all .9s ease-in-out;
}

.fade-in {
  animation: 3s forwards fadeIn;
}

.appDownloadPromptWrapper {
  z-index: 4;
  background-color: #dddcdc;
  border-radius: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  padding: 8px 16px;
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 2%;
  right: 2%;
}

.appDownloadPromptWrapper .titleWrapper {
  justify-content: end;
  align-items: center;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .appDownloadPromptWrapper .titleWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .appDownloadPromptWrapper .titleWrapper {
    width: -webkit-fill-available;
  }
}

.appDownloadPromptWrapper .titleWrapper .title {
  color: #111;
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
}

.appDownloadPromptWrapper .titleWrapper .closeButton {
  cursor: pointer;
  color: #2c2c2c !important;
  font-size: 28px !important;
}

.appDownloadPromptWrapper .subtitle {
  color: #111;
  text-align: center;
  font-size: 13px;
}

.appDownloadPromptWrapper .dontShowAgain {
  cursor: pointer;
  color: #dc004b;
  text-align: center;
  padding: 3px;
  font-size: 16px;
}

.appDownloadPromptWrapper .downloadButton {
  cursor: pointer;
  color: #fff;
  background-color: #2c2c2c;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.25rem 4rem;
  font-size: 1.25rem;
  line-height: 1.5;
  display: flex;
}

.appDownloadPromptWrapper .downloadButton:hover {
  fill: #000;
  background-color: #000;
}

.toastContainer {
  padding: 1.5rem 2rem;
}

.toastDescription {
  line-height: 1.5;
}

#videojsAudioElement {
  display: none !important;
}

.mediaUploadContainer {
  --tw-border-opacity: 1;
  border-style: dashed;
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
  border-radius: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  padding: 6rem 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .mediaUploadContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .mediaUploadContainer {
    width: -webkit-fill-available;
  }
}

.mediaUploadContainer .uploadWrapper {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mediaUploadContainer .uploadIcon {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 100px;
}

.mediaUploadContainer .directionsContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  margin-bottom: 1rem;
  display: flex;
}

.mediaUploadContainer .directionsText {
  --tw-text-opacity: 1;
  max-width: 400px;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 1.7rem;
}

.mediaUploadContainer .directionsSubtitle {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: .75rem;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper {
  flex-direction: column;
  display: flex;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .uploadItem {
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .uploadItem span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .uploadItem .fileTitle {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .uploadItem i {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  line-height: 2rem;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .progressBar {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
  height: 3px;
  margin-top: 10px;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .failedText {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1)) !important;
}

.mediaUploadContainer .uploadWrapper .uploadProgessWrapper .progressBarFailed {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

.mediaUploadContainer .rightsCheckboxContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 80%;
  margin-top: 1.5rem;
  display: flex;
}

.mediaUploadContainer .rightsCheckboxContainer .rightsTitle {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.mediaUploadContainer .rightsCheckboxContainer .checkboxWrapper {
  margin-top: 0;
}

@media (width <= 820px) {
  .sb-content {
    position: unset !important;
    right: unset !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media (width <= 640px) {
  .hiddenMobile {
    display: none !important;
  }

  .modalContainer .modalTitle {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .embla {
    --slide-height: 400px;
  }

  .embla__slide {
    min-height: 300px;
    max-height: 300px;
  }

  .appDownloadPromptWrapper .titleWrapper .title {
    font-size: 16px;
  }

  .appDownloadPromptWrapper .titleWrapper .closeButton {
    font-size: 24px !important;
  }

  .modalContainer .modal-content {
    width: 90% !important;
  }

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  .mediaUploadContainer .rightsCheckboxContainer {
    width: 100%;
  }
}

@media (width >= 641px) {
  .hiddenDesktop {
    display: none !important;
  }
}

.hover\:bg-\[\#474747\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(71 71 71 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1)) !important;
}

.hover\:from-\[\#128777\]:hover {
  --tw-gradient-from: #128777 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #12877700 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.hover\:from-\[\#BE3D7A\]:hover {
  --tw-gradient-from: #be3d7a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #be3d7a00 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.hover\:to-\[\#277104\]:hover {
  --tw-gradient-to: #277104 var(--tw-gradient-to-position) !important;
}

.hover\:to-\[\#893FE4\]:hover {
  --tw-gradient-to: #893fe4 var(--tw-gradient-to-position) !important;
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1)) !important;
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1)) !important;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.hover\:underline:hover {
  text-decoration-line: underline !important;
}

.focus\:border:focus {
  border-width: 1px !important;
}

.focus\:border-none:focus {
  border-style: none !important;
}

.focus\:border-gray-300:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1)) !important;
}

.focus\:outline-none:focus {
  outline-offset: 2px !important;
  outline: 2px solid #0000 !important;
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-gray-600:focus {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity, 1)) !important;
}

.group:hover .group-hover\:visible {
  visibility: visible !important;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1 !important;
}

@media not all and (width >= 640px) {
  .max-sm\:mt-8 {
    margin-top: 2rem !important;
  }

  .max-sm\:hidden {
    display: none !important;
  }
}

@media (width >= 640px) {
  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:mt-12 {
    margin-top: 3rem !important;
  }

  .sm\:mt-4 {
    margin-top: 1rem !important;
  }

  .sm\:grid {
    display: grid !important;
  }

  .sm\:hidden {
    display: none !important;
  }

  .sm\:h-6 {
    height: 1.5rem !important;
  }

  .sm\:w-6 {
    width: 1.5rem !important;
  }

  .sm\:w-fit {
    width: fit-content !important;
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:flex-row {
    flex-direction: row !important;
  }

  .sm\:items-center {
    align-items: center !important;
  }

  .sm\:justify-start {
    justify-content: flex-start !important;
  }

  .sm\:gap-16 {
    gap: 4rem !important;
  }

  .sm\:gap-2 {
    gap: .5rem !important;
  }

  .sm\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:px-\[1rem\] {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .sm\:text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}

@media (width >= 768px) {
  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
}

@media (width >= 1024px) {
  .lg\:ml-1 {
    margin-left: .25rem !important;
  }

  .lg\:max-w-\[45\%\] {
    max-width: 45% !important;
  }

  .lg\:max-w-\[50\%\] {
    max-width: 50% !important;
  }

  .lg\:max-w-\[70\%\] {
    max-width: 70% !important;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .lg\:text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .lg\:text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-slate-700 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(51 65 85 / var(--tw-border-opacity, 1)) !important;
  }

  .dark\:bg-gray-600 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1)) !important;
  }

  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1)) !important;
  }
}

#privacy-terms-of-use-modal {
  color: #fff;
  background: #181818;
  border-radius: 15px;
  height: 550px;
}

@media (width >= 540px) {
  #privacy-terms-of-use-modal {
    width: max-content;
  }
}

.md-content {
  width: 97%;
  height: 70% !important;
  box-shadow: none !important;
  overflow-y: scroll !important;
}

.privacy-terms-of-use-modal {
  overflow-y: hidden !important;
}

#privacy-terms-of-use-modal > .radio-form {
  margin: 10px;
  padding: 15px 30px;
}

#radio-privacy {
  display: flex;
}

#radio-privacy > input {
  opacity: 1;
  width: 20px;
  margin-right: 20px;
  left: 0;
}

#submit-privacy-terms-of-use {
  background: #363636;
  border-radius: 30px;
  margin-top: 10px;
  padding: 10px 20px;
}

.blink {
  animation: .2s steps(5, start) infinite blink-animation;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.sidebarParentContainer {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  width: 100%;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  z-index: 1;
  text-align: center;
  overscroll-behavior: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #111;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2.5rem;
  min-width: 320px;
  max-width: 320px;
  transition: width 30ms ease-in-out;
  display: none;
  overflow: hidden;
}

@supports ((-moz-appearance: none)) {
  .sidebarParentContainer {
    height: inherit;
    max-height: inherit;
  }
}

@supports ((-webkit-appearance: none)) {
  .sidebarParentContainer {
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
}

.sidebarParentContainer .sidebarHeader {
  flex-shrink: 0;
  width: 100%;
  position: relative;
}

.sidebarParentContainer .parentSidebarList {
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  height: inherit;
  max-height: inherit;
  flex-grow: 1;
  padding-bottom: 5rem;
  padding-left: .25rem;
  padding-right: .25rem;
  position: relative;
  overflow-y: scroll;
}

@supports ((-moz-appearance: none)) {
  .sidebarParentContainer .parentSidebarList {
    scrollbar-width: none;
  }
}

.sidebarParentContainer .parentSidebarList::-webkit-scrollbar {
  width: 1px;
}

.sidebarParentContainer .parentSidebarList::-webkit-scrollbar-thumb {
  background-color: #1c1c1c !important;
}

.sidebarProfilePhoto {
  object-fit: cover;
  border-radius: 9999px;
  width: 2.5rem;
  height: 2.5rem;
}

.sidebarListParentItem {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-text-opacity: 1;
  height: 3rem;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  cursor: pointer;
  border-radius: .25rem;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  overflow: hidden;
}

.sidebarListParentItem:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.sidebarListParentItem span {
  margin-left: .75rem;
}

.sidebarListParentItemProfile {
  cursor: pointer;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.sidebarListParentItemProfile:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

@supports ((-moz-appearance: none)) {
  .sidebarListParentItemProfile {
    min-width: -moz-available !important;
  }
}

@supports ((-webkit-appearance: none)) {
  .sidebarListParentItemProfile {
    min-width: -webkit-fill-available !important;
  }
}

.sidebarListChildItem {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-radius: .25rem;
  align-items: center;
  height: 1.5rem;
  margin-left: 2.5rem;
  padding: 1.5rem 1.5rem 1.5rem 3rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  overflow: hidden;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.sidebarListChildItem:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(13 13 13 / var(--tw-bg-opacity, 1)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.sidebarListChildItemProfile {
  padding-top: 1.35rem;
  padding-bottom: 1.35rem;
}

.sidebarListChildItem span {
  margin-left: 1rem;
}

.sidebarListChildItem2 {
  margin-left: 6rem !important;
}

.sidebarListChildIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.fanmailUnreadWrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(198 1 59 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
  display: flex;
}

.fanmailUnreadWrapper span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-left: 0;
  font-size: .75rem;
  line-height: 1rem;
}

.routerLinkActiveChildItem {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.startRadioButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: .75rem .5rem .75rem 5rem;
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  display: flex;
}

.startRadioButton:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.startRadioButton:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.startRadioButton .ripple-wave {
  background-image: radial-gradient(circle, #fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.startRadioButton {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.startRadioButton:active {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.startRadioButton svg {
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  flex-shrink: 0;
}

.startRadioButton i {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.startRadioButton:hover svg, .startRadioButton:hover i, .startRadioButton:hover span {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1)) !important;
}

.sidebarTagsWrapper {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: .5rem;
  margin-left: 8rem;
  display: flex;
}

.sidebarTagsWrapper > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.sidebarTags {
  --tw-bg-opacity: 1;
  background-color: rgb(29 29 29 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  cursor: pointer;
  border-radius: .75rem;
  width: max-content;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.sidebarTags:active {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.sidebarTags:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.sidebarTags:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.sidebarTags {
  margin-left: 0 !important;
}

.sidebarTags.selected {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.sidebarInputs {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
}

.sidebarInputs > div {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  height: 2.5rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  justify-content: space-between;
  align-items: center;
  padding: 0 .2rem 0 1.2rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: flex;
}

.sidebarInputs input {
  text-overflow: ellipsis;
  background-color: inherit;
  --tw-text-opacity: 1;
  width: 100%;
  height: auto;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.5rem;
  display: block;
}

.sidebarInputs input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
  outline: 2px solid #0000;
}

.sidebarInputsCloseIcon {
  --tw-text-opacity: 1;
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-left: 6px;
  padding: 5px;
  top: 8px;
}

.sidebarInputParent {
  padding-top: 1.5rem;
  padding-bottom: .5rem;
}

.about_modal a {
  --tw-text-opacity: 1;
  color: rgb(13 161 232 / var(--tw-text-opacity, 1));
  margin-top: 1.5rem;
}

.selectedSidebarFilter {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  width: .4rem;
  height: 100%;
  margin-right: 1.25rem;
}

.logoContainer {
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .logoContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .logoContainer {
    width: -webkit-fill-available;
  }
}

.logoContainer .logoWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@supports ((-moz-appearance: none)) {
  .logoContainer .logoWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .logoContainer .logoWrapper {
    width: -webkit-fill-available;
  }
}

.logoContainer .logoWrapper a {
  width: 50%;
}

.logoContainer .logoWrapper a:after {
  --tw-content: none;
  content: var(--tw-content);
}

.logoContainer .logoWrapper svg {
  width: 100%;
}

.logoContainer .logoWrapper .closeIconWrapper {
  cursor: pointer;
  position: absolute;
  right: .5rem;
}

.logoContainer .logoWrapper .closeIcon {
  width: 2rem;
  height: 2rem;
  padding-left: .25rem;
  padding-right: .25rem;
}

.logoContainer .logoBottomContainer {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
}

.logoContainer .logoBottomContainer .betaIcon {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  cursor: pointer;
  border-radius: 9999px;
  width: max-content;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.logoContainer .logoBottomContainer .betaIcon:active {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.logoContainer .logoBottomContainer i {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  line-height: 1.75rem;
  background-image: none !important;
}

.logoContainer .logoBottomContainer svg {
  cursor: pointer;
  width: 1.125rem;
  height: 1.125rem;
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1)) !important;
}

.logoContainer .logoBottomContainer .mailWrapper {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.logoContainer .logoBottomContainer .mailWrapper .unreadWrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(198 1 59 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  width: .35rem;
  height: .35rem;
}

.logoContainer .logoBottomContainer .tooltipDiv .tooltipText {
  right: -22px;
}

.infoDropdown {
  background: #191919;
  border-radius: .5rem;
  width: 100%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: .5rem .75rem 1.5rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.infoDropdown .description {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  margin-top: 1.25rem;
  line-height: 2.25rem;
  display: block;
}

.infoDropdown .linksList {
  text-transform: uppercase;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  font-size: 11px;
  display: flex;
}

.infoDropdown .linksList a {
  --tw-text-opacity: 1;
  color: rgb(223 0 76 / var(--tw-text-opacity, 1));
}

.sidebarFooter {
  text-align: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  overflow: hidden;
}

.sidebarFooter:has(.collapse.show) {
  min-height: 25rem;
}

.sidebarFooter:has(.collapse.show.hasSwitchPages) {
  min-height: 32rem;
}

.sidebarFooter .collapse {
  margin-top: .25rem;
  margin-left: 1.5rem;
  overflow-y: scroll;
}

.sidebarFooter .collapse ul {
  position: relative;
}

.sidebarFooter .sidebarFooterInnerWrapper {
  padding-bottom: 0;
}

@supports ((-moz-appearance: none)) {
  .sidebarFooter .sidebarFooterInnerWrapper {
    min-width: -moz-available !important;
  }
}

@supports ((-webkit-appearance: none)) {
  .sidebarFooter .sidebarFooterInnerWrapper {
    min-width: -webkit-fill-available !important;
  }
}

.sidebarFooter .switchPagesWrapper {
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 3rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .sidebarFooter .switchPagesWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .sidebarFooter .switchPagesWrapper {
    width: -webkit-fill-available;
  }
}

.sidebarFooter .switchPagesWrapper .divider {
  --tw-bg-opacity: 1;
  background-color: rgb(44 44 44 / var(--tw-bg-opacity, 1));
  width: 5rem;
  height: .05rem;
}

.sidebarFooter .switchPagesWrapper span {
  white-space: nowrap;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.max-vh {
  height: 100%;
}

@media (width <= 640px) {
  @supports ((-moz-appearance: none)) {
    .sidebarParentContainer {
      width: -moz-available;
      min-width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .sidebarParentContainer {
      width: -webkit-fill-available;
      min-width: -webkit-fill-available;
    }
  }

  .sidebarFooter {
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .sidebarFooter > * {
    -webkit-transform: translateZ(0);
  }

  .logoContainer .logoWrapper .closeIcon {
    right: 1rem;
  }
}

.headerBarParentContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  padding: 1rem 2rem .25rem;
  display: none;
  box-shadow: 0 0 2px #000;
}

@supports ((-moz-appearance: none)) {
  .headerBarParentContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .headerBarParentContainer {
    width: -webkit-fill-available;
  }
}

.headerBarParentContainer .rightSectionContainer {
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  width: 50%;
  display: flex;
}

.headerBarParentContainer .rightSectionContainer .sidebarToggleDiv {
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.headerBarParentContainer .rightSectionContainer .tuneLogoSmall {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

.headerBarParentContainer .rightSectionContainer .sidebarCloseOpenToggleIcon {
  cursor: pointer;
  --tw-text-opacity: 1;
  width: 2rem;
  height: 2rem;
  color: rgb(255 0 87 / var(--tw-text-opacity, 1));
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(34 34 34 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  height: 2.5rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  min-width: 0;
  padding: .9rem 2.25rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .headerBarParentContainer .rightSectionContainer .searchBarContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .headerBarParentContainer .rightSectionContainer .searchBarContainer {
    width: -webkit-fill-available;
  }
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer i {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer input {
  background-color: inherit;
  --tw-text-opacity: 1;
  height: 100%;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  outline-offset: 2px;
  outline: 2px solid #0000;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@supports ((-moz-appearance: none)) {
  .headerBarParentContainer .rightSectionContainer .searchBarContainer input {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .headerBarParentContainer .rightSectionContainer .searchBarContainer input {
    width: -webkit-fill-available;
  }
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer::-webkit-search-decoration {
  display: none;
}

.headerBarParentContainer .rightSectionContainer .searchBarContainer .searchButton {
  display: none;
}

.headerBarParentContainer .menuParentContainer {
  max-width: 33%;
  position: relative;
}

.headerBarParentContainer .menuParentContainer .profileDetailsContainer {
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  gap: 1.25rem;
  display: flex;
}

.headerBarParentContainer .profileDetailsContainer .profileImage {
  object-fit: cover;
  border-radius: 9999px;
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
}

.headerBarParentContainer .profileDetailsContainer .textDetailsWrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: .25rem;
  display: flex;
}

.headerBarParentContainer .profileDetailsContainer .textDetailsWrapper .profileName {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  word-break: break-all;
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1.5;
  overflow: hidden;
  white-space: normal !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  text-overflow: ellipsis !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
}

.headerBarParentContainer .profileDetailsContainer .textDetailsWrapper .profileType {
  text-transform: capitalize;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  word-break: break-all;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
  white-space: normal !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  text-overflow: ellipsis !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
}

.headerBarParentContainer .profileDetailsContainer .optionsDropdownIcon {
  --tw-text-opacity: 1;
  width: .75rem;
  height: .75rem;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.headerBarParentContainer .optionsDropdownContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(28 28 28 / var(--tw-bg-opacity, 1));
  z-index: 1000000000;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  width: 100%;
  min-width: 225px;
  display: flex;
  position: absolute;
  top: 4.1rem;
  right: 0;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem .75rem .75rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper {
    width: -webkit-fill-available;
  }
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul {
  max-height: 26rem;
  overflow-y: scroll;
}

@supports ((-moz-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul {
    width: -webkit-fill-available;
  }
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul::-webkit-scrollbar {
  display: none;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li {
  cursor: pointer;
  border-radius: 9999px;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li a, .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li .innerDiv {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  gap: 1rem;
  padding: .75rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  overflow: hidden;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li a:hover, .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li .innerDiv:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li i, .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li svg {
  width: 1.5rem;
  height: 1.5rem;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul li span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  word-break: break-all;
  font-size: 1rem;
  line-height: 2;
  overflow: hidden;
  white-space: normal !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  text-overflow: ellipsis !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
}

.headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper .routerLinkActiveChildItem {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
}

.headerBarParentContainer .onboardingOptions {
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.headerBarParentContainer .onboardingOptions .loginButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(44 44 44 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  display: flex;
}

.headerBarParentContainer .onboardingOptions .loginButton:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  fill: #111827;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.headerBarParentContainer .onboardingOptions .registerButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(239 239 239 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
  display: flex;
}

.headerBarParentContainer .onboardingOptions .registerButton:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.5rem;
  padding-left: .75rem;
  padding-right: .75rem;
  display: flex;
  position: relative;
}

@supports ((-moz-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper {
    width: -webkit-fill-available;
  }
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper span {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  word-break: break-all;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
  white-space: normal !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  text-overflow: ellipsis !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper i, .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper svg {
  --tw-text-opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  line-height: 1.5;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu {
  --tw-bg-opacity: 1;
  background-color: rgb(22 22 22 / var(--tw-bg-opacity, 1));
  border-radius: .5rem;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem .5rem;
  display: flex;
  position: absolute;
}

@supports ((-moz-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu {
    width: -webkit-fill-available;
  }
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu .titleWrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu .titleWrapper span {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.5rem;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .switchProfileDropdownMenu .closeIcon {
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  right: 0;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .75rem;
  display: flex;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem {
  cursor: pointer;
  border-radius: 9999px;
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  padding: .5rem;
  display: flex;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(35 35 35 / var(--tw-bg-opacity, 1));
}

@supports ((-moz-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem {
    width: -webkit-fill-available;
  }
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem img {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
}

.headerBarParentContainer .optionsDropdownContainer .switchProfileDropdownMenu .list .switchProfileItem span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@container solidPageContainer (width >= 640px) {
  .headerBarParentContainer .rightSectionContainer .tuneLogoSmall {
    display: none;
  }
}

@container solidPageContainer (width <= 640px) {
  .headerBarParentContainer {
    gap: 1rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .headerBarParentContainer.optimizeHeaderForMobile {
    flex-direction: column;
  }

  .headerBarParentContainer .rightSectionContainer {
    min-width: 0;
  }

  @supports ((-moz-appearance: none)) {
    .headerBarParentContainer .rightSectionContainer {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .headerBarParentContainer .rightSectionContainer {
      width: -webkit-fill-available;
    }
  }

  .headerBarParentContainer .profileDetailsContainer .textDetailsWrapper {
    display: none;
  }

  .headerBarParentContainer .profileDetailsContainer .profileImage {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    min-height: 3rem;
  }

  .headerBarParentContainer .optionsDropdownContainer .dropdownListWrapper ul {
    max-height: 22rem;
  }

  .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper {
    gap: .25rem;
  }

  .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper i, .headerBarParentContainer .optionsDropdownContainer .switchProfileWrapper .buttonWrapper svg {
    width: 1rem;
    height: 1rem;
  }
}

.playerParentContainer {
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  display: flex;
}

#videojsAudioElement .vjs-text-track-display div {
  display: none;
}

.fullScreenPlayerParentWrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  z-index: 10000;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
}

.fullScreenPlayerParentWrapper .fullScreenPlayerCloseIcon {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(54 54 54 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  width: 3rem;
  height: 3rem;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
  opacity: .75;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .25rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: .75rem;
  right: 1rem;
  z-index: 10 !important;
}

.fullScreenPlayerParentWrapper .fullScreenPlayerCloseIcon:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  color: #111827bf;
  opacity: 1;
}

.fullScreenPlayerParentWrapper .fullScreenPlayerCloseIcon:disabled {
  cursor: not-allowed;
  opacity: .25;
}

.fullScreenPlayerParentWrapper video {
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.fullScreenPlayerContainer {
  background: linear-gradient(#0000, #111);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8rem;
  padding-left: 8rem;
  padding-right: 8rem;
  display: flex;
  position: relative;
}

@supports ((-moz-appearance: none)) {
  .fullScreenPlayerContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .fullScreenPlayerContainer {
    width: -webkit-fill-available;
  }
}

.fullScreenPlayerContainer .songDetailsContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.fullScreenPlayerContainer .songDetailsContainer .songName {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.25rem;
}

.fullScreenPlayerContainer .songDetailsContainer .artistName {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.fullScreenPlayerContainer .songDetailsContainer .songName, .fullScreenPlayerContainer .songDetailsContainer .artistName {
  white-space: nowrap;
  word-break: break-all;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis !important;
  line-clamp: 1 !important;
}

@supports ((-moz-appearance: none)) {
  .fullScreenPlayerContainer .songDetailsContainer .songName, .fullScreenPlayerContainer .songDetailsContainer .artistName {
    max-width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .fullScreenPlayerContainer .songDetailsContainer .songName, .fullScreenPlayerContainer .songDetailsContainer .artistName {
    max-width: -webkit-fill-available;
  }
}

.fullScreenPlayerContainer .songDetailsContainer .songImage {
  cursor: pointer;
  object-fit: cover;
  border-radius: 9999px;
  width: 22rem;
  min-width: 22rem;
  height: 22rem;
  min-height: 22rem;
  margin-top: 3rem;
}

.fullScreenPlayerContainer .songDetailsContainer .songImage.rotate {
  animation: 10s linear infinite songImageRotation;
}

@keyframes songImageRotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.fullScreenPlayerContainer .songDetailsContainer .songImage.pauseRotation {
  animation-play-state: paused;
}

.fullScreenPlayerContainer .playerControlsContainer {
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 75%;
  display: flex;
}

.fullScreenPlayerContainer .playerControlsContainer.videoShowing {
  margin-bottom: 3rem;
}

.fullScreenPlayerContainer .playerControlsContainer .trackerAndVolumeControlsWrapper {
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .fullScreenPlayerContainer .playerControlsContainer .trackerAndVolumeControlsWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .fullScreenPlayerContainer .playerControlsContainer .trackerAndVolumeControlsWrapper {
    width: -webkit-fill-available;
  }
}

.fullScreenPlayerContainer .playerControlsContainer .trackerAndVolumeControlsWrapper .volumeVisualizerIconsWrapper {
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.fullScreenPlayerContainer .playerControlsContainer .trackerAndVolumeControlsWrapper .volumeVisualizerIconsWrapper .visualizerIcon {
  cursor: pointer;
  width: 1.4rem;
  height: 1.4rem;
  --tw-text-opacity: 1 !important;
  color: rgb(124 124 124 / var(--tw-text-opacity, 1)) !important;
}

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper {
  justify-content: center;
  align-items: center;
  gap: 9rem;
  display: flex;
}

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .playIcon {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(32 32 32 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  display: flex;
}

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .playIcon svg {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .skipIcon {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  --tw-text-opacity: 1 !important;
  color: rgb(124 124 124 / var(--tw-text-opacity, 1)) !important;
}

.fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .shuffleIcon, .fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper .repeatIcon {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  display: block;
}

@media (width <= 640px) {
  .fullScreenPlayerContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @supports ((-moz-appearance: none)) {
    .fullScreenPlayerContainer .playerControlsContainer {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .fullScreenPlayerContainer .playerControlsContainer {
      width: -webkit-fill-available;
    }
  }

  .fullScreenPlayerContainer .playerControlsContainer.videoShowing {
    margin-bottom: 7rem;
  }

  @supports ((-moz-appearance: none)) {
    .fullScreenPlayerContainer .songDetailsContainer {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .fullScreenPlayerContainer .songDetailsContainer {
      width: -webkit-fill-available;
    }
  }

  .fullScreenPlayerContainer .songDetailsContainer .songImage {
    width: 15rem;
    min-width: 15rem;
    height: 15rem;
    min-height: 15rem;
  }

  .fullScreenPlayerContainer .playerControlsContainer .playSkipControlsWrapper {
    justify-content: center;
    align-items: center;
    gap: 3rem;
    display: flex;
  }
}

.trackerAndTimeWrapper {
  justify-content: flex-start;
  align-items: center;
  gap: 1.25rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .trackerAndTimeWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .trackerAndTimeWrapper {
    width: -webkit-fill-available;
  }
}

.trackerAndTimeWrapper .timeElapsed, .trackerAndTimeWrapper .timeSongLength {
  --tw-text-opacity: 1;
  min-width: 2rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: .75rem;
  line-height: 1rem;
}

.trackerAndTimeWrapper .trackerWrapperOuter {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  height: 1rem;
  display: flex;
  position: relative;
}

@supports ((-moz-appearance: none)) {
  .trackerAndTimeWrapper .trackerWrapperOuter {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .trackerAndTimeWrapper .trackerWrapperOuter {
    width: -webkit-fill-available;
  }
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(28 28 28 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-start;
  align-items: center;
  height: .25rem;
  display: flex;
  position: relative;
}

@supports ((-moz-appearance: none)) {
  .trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper {
    width: -webkit-fill-available;
  }
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper .tracker {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 87 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-end;
  align-items: center;
  height: .25rem;
  transition: width 1s;
  display: flex;
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper .dragHandle {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: left 1s;
  display: flex;
  position: absolute;
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper .dragHandle .dragIcon {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.trackerAndTimeWrapper .trackerWrapperOuter .trackerWrapper .dragHandle .dragTimeTooltip {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 87 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 1rem;
  padding: .4rem .7rem;
  font-size: .7rem;
  position: absolute;
  bottom: 1.25rem;
}

.volumeControlsWrapper {
  justify-content: flex-end;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.volumeControlsWrapper.volumeHiddenOnDesktop {
  display: none;
}

.volumeControlsWrapper.volumeHiddenOnMobile {
  display: flex;
}

.volumeControlsWrapper svg {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  --tw-text-opacity: 1 !important;
  color: rgb(124 124 124 / var(--tw-text-opacity, 1)) !important;
}

.volumeControlsWrapper .volumeTrackerWrapper {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(28 28 28 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  height: .25rem;
  display: none;
  position: relative;
}

.volumeControlsWrapper .volumeTrackerWrapper.showVolumeTracker {
  display: flex;
}

.volumeControlsWrapper .volumeTrackerWrapper .tracker {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 87 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-end;
  align-items: center;
  height: .25rem;
  transition: width 1s;
  display: flex;
}

.volumeControlsWrapper .volumeTrackerWrapper .tracker .dragIcon {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  width: .5rem;
  height: .5rem;
  transition: left 1s;
  position: absolute;
}

@media (width <= 960px) {
  .volumeControlsWrapper {
    flex-direction: column-reverse;
    position: relative;
  }

  .volumeControlsWrapper .volumeTrackerWrapper {
    flex-direction: column;
    justify-content: flex-end;
    width: .25rem;
    height: 60px;
    position: absolute;
    bottom: 25px;
  }

  .volumeControlsWrapper .volumeTrackerWrapper .tracker {
    flex-direction: column;
    width: .25rem;
    height: 0;
    transition: height 1s;
  }

  .volumeControlsWrapper .volumeTrackerWrapper .tracker .dragIcon {
    width: 1rem;
    height: 1rem;
    transition: bottom 1s;
  }
}

@media (width <= 720px) {
  .volumeControlsWrapper.volumeHiddenOnDesktop {
    display: flex;
  }

  .volumeControlsWrapper.volumeHiddenOnMobile {
    display: none;
  }
}

.visualizerContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  height: inherit;
  z-index: 10000;
  position: relative;
}

.visualizerContainer canvas {
  position: absolute;
}

.visualizerContainer .visualizerControls {
  justify-content: flex-end;
  align-items: center;
  gap: 2.5rem;
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.visualizerContainer .visualizerControls .visualizerControlButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(54 54 54 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  width: 3rem;
  height: 3rem;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
  opacity: .75;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: .25rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  z-index: 10 !important;
}

.visualizerContainer .visualizerControls .visualizerControlButton:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  color: #111827bf;
  opacity: 1;
}

.visualizerContainer .visualizerControls .visualizerControlButton:disabled {
  cursor: not-allowed;
  opacity: .25;
}

.bottomPlayerContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px #000;
}

.bottomPlayerContainer .playlistDropupSection {
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  display: flex;
  position: absolute;
  left: 0;
}

.bottomPlayerContainer .playlistDropupSection .playlistDropupSectionInner {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.bottomPlayerContainer .playlistDropupSection .playlistDropupSectionInner .playlistIcon {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.bottomPlayerContainer .playlistDropupSection .playlistDropupSectionInner .playlistCounterText {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.5rem;
}

.bottomPlayerContainer .playerRightSection {
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
  padding-right: 1rem;
  display: flex;
  position: absolute;
  right: 0;
}

.bottomPlayerContainer .playerRightSection .secondaryControls {
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
  display: flex;
}

.bottomPlayerContainer .playerRightSection .secondaryControls .visualizerIcon {
  cursor: pointer;
  width: 1.4rem;
  height: 1.4rem;
}

.bottomPlayerContainer .playerRightSection .secondaryControls .shuffleIcon, .bottomPlayerContainer .playerRightSection .secondaryControls .repeatIcon {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection {
  position: relative;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner {
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .walletBalanceWrapper {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .walletBalanceWrapper .walletBalanceText {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner svg {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.bottomPlayerContainer .playerRightSection .addToPlaylistDropupWrapper {
  position: relative;
}

@media (width <= 1280px) {
  .bottomPlayerContainer .playerRightSection, .bottomPlayerContainer .playerRightSection .secondaryControls {
    gap: 2rem;
  }
}

@media (width <= 960px) {
  .bottomPlayerContainer .playerRightSection, .bottomPlayerContainer .playerRightSection .secondaryControls {
    gap: 1.25rem;
  }

  .bottomPlayerContainer .playerRightSection .secondaryControls .shuffleIcon, .bottomPlayerContainer .playerRightSection .secondaryControls .repeatIcon {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
  }

  .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner {
    gap: .5rem;
  }

  .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .walletBalanceWrapper .walletBalanceText {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (width <= 720px) {
  .bottomPlayerContainer {
    align-items: flex-end;
  }

  .bottomPlayerContainer .playlistDropupSection {
    bottom: 2rem;
  }

  .bottomPlayerContainer .playlistDropupSection .playlistDropupSectionInner {
    align-items: flex-end;
  }

  .bottomPlayerContainer .playerRightSection {
    bottom: 2rem;
  }

  .bottomPlayerContainer .playerRightSection .secondaryControls, .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .walletBalanceWrapper, .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .spinner {
    display: none;
  }

  .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner svg {
    width: 2rem;
    height: 2rem;
  }
}

.playQueueDropupContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(36 36 36 / var(--tw-bg-opacity, 1));
  z-index: 10000;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  flex-direction: column;
  width: 300px;
  max-height: 400px;
  padding-top: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
  position: absolute;
  bottom: 4.6rem;
  left: 0;
}

.playQueueDropupContainer .playQueueTitle {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.playQueueDropupContainer .playQueueTitle span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-top: 1.5rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.playQueueDropupContainer .playQueueTitle i {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  line-height: 2rem;
  position: absolute;
  right: 0;
}

.playQueueDropupContainer .savePlaylistTitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
  margin-top: 1.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.playQueueDropupContainer .playlistNameInput {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  height: 2.75rem;
  min-height: 2.75rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: .25rem 2.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
}

.playQueueDropupContainer .playlistNameInput:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.playQueueDropupContainer .buttonContainer {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin: .25rem 1rem;
  display: grid;
}

@supports ((-moz-appearance: none)) {
  .playQueueDropupContainer .buttonContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .playQueueDropupContainer .buttonContainer {
    width: -webkit-fill-available;
  }
}

.playQueueDropupContainer .buttonContainer div {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: .75rem;
  display: flex;
}

.playQueueDropupContainer .buttonContainer span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.playQueueDropupContainer .buttonContainer i {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.5rem;
}

.playQueueDropupContainer .playQueueSongsContainer {
  flex-direction: column;
  gap: .25rem;
  margin-top: .5rem;
  display: flex;
  overflow-y: scroll;
}

@supports ((-moz-appearance: none)) {
  .playQueueDropupContainer .playQueueSongsContainer {
    scrollbar-width: none;
  }
}

.playQueueDropupContainer .playQueueSongsContainer::-webkit-scrollbar {
  width: 2px !important;
}

.playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem {
  border-bottom: 1px solid #1c1c1c;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songLeftSection {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songLeftSection .dragSongIcon {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  line-height: 2rem;
}

.playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songLeftSection .playSongIcon {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1)) !important;
}

.playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songDetailsWrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songDetailsWrapper .title {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  word-break: break-all;
  font-size: 1.25rem;
  line-height: 1;
  overflow: hidden;
  white-space: normal !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  text-overflow: ellipsis !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
}

.playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .songDetailsWrapper .artistName {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  word-break: break-all;
  font-size: .75rem;
  line-height: 1;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
}

.playQueueDropupContainer .playQueueSongsContainer .playQueueSongItem .deleteSongIcon {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  line-height: 2rem;
}

.playQueueDropupContainer .empytyQueueContainer {
  justify-content: center;
  align-items: center;
  padding: 6rem .75rem;
  display: flex;
}

.playQueueDropupContainer .empytyQueueContainer span {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

@media (width <= 720px) {
  .playQueueDropupContainer {
    bottom: 4rem;
  }
}

#addToPlaylistBottomsheetDropupParent.sb-content {
  --tw-bg-opacity: 1;
  background-color: rgb(36 36 36 / var(--tw-bg-opacity, 1));
}

#addToPlaylistBottomsheetDropupParent .sb-handle-container {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.addToPlaylistDropupContainer {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  flex-direction: column;
  min-height: 500px;
  max-height: 500px;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer {
  z-index: 10000;
  container-type: inline-size;
}

.addToPlaylistDropupContainer .addToPlaylistTopBar {
  --tw-bg-opacity: 1;
  background-color: rgb(36 36 36 / var(--tw-bg-opacity, 1));
  align-items: center;
  padding-top: 1rem;
  display: flex;
  left: 1rem;
  right: 1rem;
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistTopBar {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistTopBar {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer .addToPlaylistTopBar .dropupTitle {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin: 0 auto;
  font-size: 1.3rem;
  font-weight: 600;
}

.addToPlaylistDropupContainer .addToPlaylistTopBar .addToPlaylistDropupCloseIcon {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  line-height: 2rem;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent {
  --tw-bg-opacity: 1;
  background-color: rgb(36 36 36 / var(--tw-bg-opacity, 1));
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  margin-top: 1.5rem;
  padding: 0 2rem 2rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox {
  --tw-bg-opacity: 1;
  background-color: rgb(47 47 47 / var(--tw-bg-opacity, 1));
  border-radius: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  min-height: 239px;
  max-height: 239px;
  padding: 2rem 4rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .selectedSaveOptionTitleWrapper {
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .selectedSaveOptionTitleWrapper span {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  font-weight: 500;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .selectedSaveOptionTitleWrapper i {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  font-size: 1rem;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .alternativeOptionTitleWrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .alternativeOptionTitleWrapper span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.1rem;
  font-weight: 600;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .alternativeOptionTitleWrapper i {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.1rem;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .existingPlaylistsContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: .25rem;
  display: flex;
  overflow-y: scroll;
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .existingPlaylistsContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .existingPlaylistsContainer {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(93 93 93 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  font-size: 1rem;
  line-height: 0;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem i {
  color: #ff0055dc;
  font-size: 1.5rem;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem .inputLabel {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem .inputInnerWrapper {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem .inputInnerWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .inputItem .inputInnerWrapper {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .inputInnerWrapper input {
  --tw-bg-opacity: 1;
  background-color: rgb(30 30 30 / var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  outline-offset: 2px;
  border-radius: 9999px;
  outline: 2px solid #0000;
  align-items: center;
  padding: 1rem 3rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .inputInnerWrapper input:focus {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

@supports ((-moz-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .inputInnerWrapper input {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent .inputInnerWrapper input {
    width: -webkit-fill-available;
  }
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .submitButton {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 6rem;
  display: flex;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .noPlaylistsMessage {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1rem;
}

.addToPlaylistDropupContainer .addToPlaylistMainContent .submitButton span {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
  font-size: 1.1rem;
}

@media (width <= 640px) {
  .addToPlaylistDropupContainer .addToPlaylistMainContent {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox {
    padding: 2rem 1rem 1rem;
  }

  .addToPlaylistDropupContainer .addToPlaylistMainContent .contentBox .playlistSongItem span {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .addToPlaylistDropupContainer .addToPlaylistMainContent .submitButton {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  @supports ((-moz-appearance: none)) {
    .addToPlaylistDropupContainer .addToPlaylistMainContent .submitButton {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .addToPlaylistDropupContainer .addToPlaylistMainContent .submitButton {
      width: -webkit-fill-available;
    }
  }
}

.sb-overflow-hidden {
  overflow: hidden;
}

.sb-overlay {
  z-index: 999;
  background-color: #00000080;
  align-items: flex-end;
  display: flex;
  position: fixed;
  inset: 0;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
}

@keyframes slide-down {
  to {
    transform: translateY(100%);
  }
}

.sb-content {
  background-color: #2a2929;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  width: 100%;
  animation: .2s slide-up;
}

.sb-content.sb-is-snapping {
  transition: transform .2s;
}

.sb-content.sb-is-closing {
  animation: .2s slide-down;
}

.sb-handle-container {
  margin: 0 auto;
  padding: 24px 0;
}

.sb-handle {
  text-align: center;
  background-color: #000;
  width: 60px;
  height: 2px;
  margin: auto;
}

.playerControlsContainer {
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .playerControlsContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .playerControlsContainer {
    width: -webkit-fill-available;
  }
}

.playerControlsContainer .songContainer {
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  padding-left: 7rem;
  display: flex;
}

.playerControlsContainer .songContainer.hideSongContainer {
  visibility: hidden;
}

.playerControlsContainer .songContainer .songImage {
  cursor: pointer;
  object-fit: cover;
  border-radius: 9999px;
  width: 5.5rem;
  min-width: 5.5rem;
  height: 5.5rem;
  min-height: 5.5rem;
}

.playerControlsContainer .songContainer .songImage.rotate {
  animation: 10s linear infinite songImageRotation;
}

.playerControlsContainer .songContainer .songImage.pauseRotation {
  animation-play-state: paused;
}

.playerControlsContainer .songContainer .songDetailsWrapper {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: .5rem;
  max-width: 45%;
  display: flex;
}

.playerControlsContainer .songContainer .songDetailsWrapper .songName {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.playerControlsContainer .songContainer .songDetailsWrapper .artistName {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.5rem;
}

.playerControlsContainer .songContainer .songDetailsWrapper .songName, .playerControlsContainer .songContainer .songDetailsWrapper .artistName {
  white-space: nowrap;
  word-break: break-all;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis !important;
  line-clamp: 1 !important;
}

@supports ((-moz-appearance: none)) {
  .playerControlsContainer .songContainer .songDetailsWrapper .songName, .playerControlsContainer .songContainer .songDetailsWrapper .artistName {
    max-width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .playerControlsContainer .songContainer .songDetailsWrapper .songName, .playerControlsContainer .songContainer .songDetailsWrapper .artistName {
    max-width: -webkit-fill-available;
  }
}

.playerControlsContainer .songContainer .playerActionButtonsWrapper {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2rem;
  display: flex;
}

.playerControlsContainer .songContainer .playerActionButtonsWrapper .collapseIcon {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(167 167 167 / var(--tw-text-opacity, 1));
  font-size: 2rem;
  font-weight: 600;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper {
  position: relative;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper .actionButtonsIcon {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(124 124 124 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  line-height: 2rem;
  display: none;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  display: flex;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons i {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(124 124 124 / var(--tw-text-opacity, 1));
  font-size: 1.5rem;
  line-height: 1;
}

.playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons .visualizerIcon {
  display: none;
}

.playerControlsContainer .centerDiv {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  width: 30%;
  margin-left: -15%;
  display: flex;
  position: absolute;
  left: 50%;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper {
  justify-content: center;
  align-items: center;
  gap: 3rem;
  display: flex;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper .playIcon {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(32 32 32 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  display: flex;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper .playIcon svg {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1)) !important;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper .skipIcon {
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  --tw-text-opacity: 1 !important;
  color: rgb(124 124 124 / var(--tw-text-opacity, 1)) !important;
}

.playerControlsContainer .centerDiv .playSkipControlsWrapper .shuffleIcon, .playerControlsContainer .centerDiv .playSkipControlsWrapper .repeatIcon {
  display: none;
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer {
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .playerControlsContainer .centerDiv .trackerVolumeControlsContainer {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .playerControlsContainer .centerDiv .trackerVolumeControlsContainer {
    width: -webkit-fill-available;
  }
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer.hideTrackerVolumeControls {
  display: none;
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper {
  justify-content: flex-start;
  align-items: center;
  gap: 1.25rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper {
    width: -webkit-fill-available;
  }
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .timeElapsed, .playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .timeSongLength {
  --tw-text-opacity: 1;
  min-width: 2rem;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: .75rem;
  line-height: 1rem;
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .trackerWrapper {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(28 28 28 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-start;
  align-items: center;
  height: .25rem;
  display: flex;
  position: relative;
}

@supports ((-moz-appearance: none)) {
  .playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .trackerWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .trackerWrapper {
    width: -webkit-fill-available;
  }
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .trackerWrapper .tracker {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 87 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-end;
  align-items: center;
  height: .25rem;
  transition: width 1s;
  display: flex;
}

.playerControlsContainer .centerDiv .trackerVolumeControlsContainer .trackerAndTimeWrapper .trackerWrapper .dragIcon {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
  transition: left 1s;
  position: absolute;
}

@media (width <= 1360px) {
  .playerControlsContainer .songContainer .songDetailsWrapper {
    max-width: 45%;
  }
}

@media (width <= 1280px) {
  .playerControlsContainer .songContainer .songDetailsWrapper {
    max-width: 300px;
  }

  .playerControlsContainer .songContainer .songActionButtonsWrapper .actionButtonsIcon {
    display: block;
  }

  .playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons {
    --tw-bg-opacity: 1;
    background-color: rgb(36 36 36 / var(--tw-bg-opacity, 1));
    z-index: 10000;
    border-radius: 1.5rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 1.5rem;
    display: none;
    position: absolute;
    bottom: 1.5rem;
  }

  .playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons.showActionButtonsDropup {
    display: flex;
  }
}

@media (width <= 960px) {
  .playerControlsContainer .songContainer .songImage {
    width: 4.5rem;
    min-width: 4.5rem;
    height: 4.5rem;
    min-height: 4.5rem;
  }

  .playerControlsContainer .songContainer .songDetailsWrapper {
    max-width: 115px;
  }

  .playerControlsContainer .songContainer .songDetailsWrapper .songName {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .playerControlsContainer .centerDiv {
    width: 28%;
    margin-left: -14%;
    left: 50%;
  }

  .playerControlsContainer .centerDiv .playSkipControlsWrapper {
    gap: 2rem;
  }
}

@media (width <= 720px) {
  .playerControlsContainer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .playerControlsContainer .songContainer {
    padding-left: 0;
  }

  @supports ((-moz-appearance: none)) {
    .playerControlsContainer .songContainer {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .playerControlsContainer .songContainer {
      width: -webkit-fill-available;
    }
  }

  .playerControlsContainer .songContainer.hideSongContainer {
    display: none;
  }

  .playerControlsContainer .songContainer .songDetailsWrapper {
    min-width: 0;
  }

  @supports ((-moz-appearance: none)) {
    .playerControlsContainer .songContainer .songDetailsWrapper {
      width: -moz-available;
      max-width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .playerControlsContainer .songContainer .songDetailsWrapper {
      width: -webkit-fill-available;
      max-width: -webkit-fill-available;
    }
  }

  .playerControlsContainer .songContainer .songActionButtonsWrapper .actionButtonsIcon {
    display: none;
  }

  .playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons {
    position: unset;
    background-color: #0000;
    flex-direction: row;
    align-items: flex-end;
    padding: 0;
    display: flex;
  }

  .playerControlsContainer .songContainer .songActionButtonsWrapper .songActionButtons .visualizerIcon {
    cursor: pointer;
    width: 1.4rem;
    height: 1.4rem;
    display: block;
    --tw-text-opacity: 1 !important;
    color: rgb(124 124 124 / var(--tw-text-opacity, 1)) !important;
  }

  .playerControlsContainer .centerDiv {
    position: unset;
    flex-direction: column-reverse;
    gap: 1rem;
    margin-left: 0;
  }

  @supports ((-moz-appearance: none)) {
    .playerControlsContainer .centerDiv {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .playerControlsContainer .centerDiv {
      width: -webkit-fill-available;
    }
  }

  .playerControlsContainer .centerDiv .playSkipControlsWrapper {
    gap: 2rem;
  }

  .playerControlsContainer .centerDiv .playSkipControlsWrapper .shuffleIcon, .playerControlsContainer .centerDiv .playSkipControlsWrapper .repeatIcon {
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;
    display: block;
  }
}

#walletBottomsheetDropupParent {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
}

#walletBottomsheetDropup .dropupTopBar {
  justify-content: space-between;
}

#walletBottomsheetDropup .dropupTopBar .goToWalletButtonWrapper {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: flex;
}

#walletBottomsheetDropup .dropupTopBar .goToWalletButtonWrapper i {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1.25rem;
  line-height: 1.75rem;
}

#walletBottomsheetDropup .dropupTopBar .goToWalletButtonWrapper span {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: 1rem;
  line-height: 1.5rem;
}

.walletDropupContainer {
  z-index: 10000;
  min-height: 150px;
  max-height: 450px;
  width: inherit;
  height: inherit;
  flex-direction: column;
  display: flex;
  container-type: inline-size;
}

@container (width <= 640px) {
  .walletDropupContainer .walletTransactionsContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
  }

  .walletDropupContainer .walletTransactionsContainer::-webkit-scrollbar {
    display: none;
  }

  .walletDropupContainer .walletTransactionsContainer .headerSection {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }

  .walletDropupContainer .walletTransactionsContainer .headerSection .title {
    margin-top: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .walletDropupContainer .walletTransactionsContainer .headerSection .infoSection {
    flex-direction: column;
  }

  .walletDropupContainer .walletTransactionsContainer .headerSection .infoSection a.buyJAMButton {
    cursor: pointer;
    display: flex;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem {
    flex-direction: column;
    gap: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @supports ((-moz-appearance: none)) {
    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper {
      width: -webkit-fill-available;
    }
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .firstDiv {
    gap: .5rem;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
    justify-content: space-between;
  }

  @supports ((-moz-appearance: none)) {
    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
      width: -webkit-fill-available;
    }
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .title {
    font-size: .75rem;
    line-height: 1rem;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistText {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .subTitle {
    max-width: 10rem;
    font-size: .75rem;
    line-height: 1rem;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer {
    justify-content: flex-end;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .timeCount {
    font-size: .75rem;
    line-height: 1rem;
    overflow: visible;
    display: block !important;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistImage {
    display: none !important;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper {
    justify-content: space-between;
    gap: .75rem;
  }

  @supports ((-moz-appearance: none)) {
    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper {
      width: -webkit-fill-available;
    }
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 1rem;
  }

  @supports ((-moz-appearance: none)) {
    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
      width: -webkit-fill-available;
    }
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper {
    min-width: fit-content;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .timeCount {
    display: none !important;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper {
    flex-direction: row;
    justify-content: flex-start;
    gap: .25rem;
    min-width: fit-content;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .tokenAmount, .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .usdAmount {
    font-size: .75rem;
    line-height: 1rem;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .listenTime {
    min-width: fit-content;
    font-size: .75rem;
    line-height: 1rem;
  }

  .walletDropupContainer .walletTransactionsContainer .transactionItem .rightWrapper .statusIcon {
    display: none;
  }

  .walletDropupContainer .walletTransactionsContainer .loadMore {
    justify-content: center;
    margin-top: .75rem;
    display: flex;
  }

  .walletDropupContainer .walletTransactionsContainer .loadMore span {
    cursor: pointer;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width <= 720px) {
  .walletDropupContainer {
    bottom: 4rem;
  }
}

.walletTransactionsContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(36 36 36 / var(--tw-bg-opacity, 1));
  border-radius: 2.5rem;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  padding: 3rem 2rem 2rem;
  display: flex;
}

.walletTransactionsContainer .headerSection {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.walletTransactionsContainer .headerSection .title {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: 1.7rem;
  font-weight: 700;
}

.walletTransactionsContainer .headerSection .infoSection {
  flex-wrap: wrap;
  justify-content: center;
  gap: .75rem;
  display: flex;
}

.walletTransactionsContainer .headerSection .infoSection div, .walletTransactionsContainer .headerSection .infoSection a {
  --tw-bg-opacity: 1;
  background-color: rgb(52 52 52 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  padding: .75rem 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
}

.walletTransactionsContainer .headerSection .infoSection div:hover, .walletTransactionsContainer .headerSection .infoSection a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 71 71 / var(--tw-bg-opacity, 1));
}

.walletTransactionsContainer .headerSection .infoSection div span, .walletTransactionsContainer .headerSection .infoSection a span, .walletTransactionsContainer .headerSection .infoSection a svg {
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.walletTransactionsContainer .headerSection .infoSection a.txExplorerBtn span {
  --tw-text-opacity: 1;
  color: rgb(52 51 51 / var(--tw-text-opacity, 1));
}

.walletTransactionsContainer .transactionItem {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity, 1));
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .75rem;
  justify-content: space-between;
  align-items: center;
  margin-top: .25rem;
  padding: .75rem 1.5rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .leftWrapper .firstDiv {
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .leftWrapper .firstDiv img {
  object-fit: cover;
  border-radius: 9999px;
  width: 2.5rem;
  height: 2.5rem;
}

.walletTransactionsContainer .transactionItem .leftWrapper .firstDiv .imagePlaceholder {
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .leftWrapper .firstDiv .imagePlaceholder i {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: .75rem;
  font-weight: 600;
  line-height: 1rem;
}

.walletTransactionsContainer .transactionItem .leftWrapper {
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper, .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer {
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .title {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .subTitle {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer {
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .timeCount {
  display: none !important;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistImage img {
  object-fit: cover;
  border-radius: 9999px;
  width: 1.25rem;
  height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistText {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .leftWrapper .textWrapper a, .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper span {
  word-break: break-all;
  overflow: hidden;
  white-space: normal !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  display: -webkit-box !important;
}

.walletTransactionsContainer .transactionItem .rightWrapper, .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper {
  justify-content: flex-end;
  align-items: center;
  gap: .25rem;
  min-width: 4rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper a, .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper span {
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: .75rem;
  line-height: 1rem;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .timeCount {
  --tw-text-opacity: 1;
  min-width: 3rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper {
  --tw-text-opacity: 1;
  min-width: 7.8rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: .5rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .tokenAmount {
  color: inherit;
  font-size: .875rem;
  line-height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .usdAmount {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionTimeDiv {
  flex-direction: column;
  align-items: flex-end;
  gap: .5rem;
  min-width: 11rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .rightWrapper .transactionTime {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .rightWrapper .listenTimeDiv {
  justify-content: flex-end;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.walletTransactionsContainer .transactionItem .rightWrapper .listenTimeDiv i {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.walletTransactionsContainer .transactionItem .rightWrapper .statusIcon {
  font-size: 1.5rem;
  line-height: 2rem;
}

.filterContainer {
  justify-content: flex-end;
  margin-bottom: .5rem;
  display: flex;
}

.filterContainer .dropdown-menu {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 200px;
  margin-right: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: none;
  position: relative;
}

.filterContainer .dropdown-menu ul {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  width: 100%;
  position: absolute;
}

.filterContainer .dropdown-menu .list-item {
  cursor: pointer;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  padding: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: block;
}

.filterContainer .dropdown-menu .list-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
  border-radius: 9999px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.filterContainer .dropdown-menu .list-item-title {
  cursor: auto;
  --tw-text-opacity: 1;
  color: rgb(56 91 208 / var(--tw-text-opacity, 1));
  font-size: .875rem;
  line-height: 1.25rem;
}

.filterContainer .dropdown-menu .list-item-title:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(56 91 208 / var(--tw-text-opacity, 1));
}

.walletTransactionsContainer .dropdownContainer {
  --tw-bg-opacity: 1;
  background-color: rgb(34 34 34 / var(--tw-bg-opacity, 1));
  border-radius: 30px;
  width: 100%;
  position: relative;
}

.walletTransactionsContainer .dropdownContainer .selectionField {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(29 29 29 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  height: 3rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  outline-offset: 2px;
  border-radius: 9999px;
  outline: 2px solid #0000;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: flex;
}

.walletTransactionsContainer .dropdownContainer .selectionField:focus {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.walletTransactionsContainer .dropdownContainer .selectionField div {
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.walletTransactionsContainer .dropdownContainer .selectionField img {
  border-radius: 9999px;
  width: 2.5rem;
  height: 2.5rem;
}

.walletTransactionsContainer .dropdownContainer .selectionField span {
  -webkit-line-clamp: 1;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow-x: hidden;
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown {
  z-index: 2147483647;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  display: flex;
  position: absolute;
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper {
  --tw-bg-opacity: 1;
  background-color: rgb(34 34 34 / var(--tw-bg-opacity, 1));
  border-radius: 0 0 30px 30px;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem .75rem .75rem;
  display: flex;
}

@supports ((-moz-appearance: none)) {
  .walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper {
    width: -webkit-fill-available;
  }
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown input {
  --tw-bg-opacity: 1;
  background-color: rgb(30 30 30 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  height: 3.5rem;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 13px;
  display: block;
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown input:focus {
  border-width: 0;
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown input {
  outline: none !important;
}

@supports ((-moz-appearance: none)) {
  .walletTransactionsContainer .dropdownContainer .selectionDropDown input {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .walletTransactionsContainer .dropdownContainer .selectionDropDown input {
    width: -webkit-fill-available;
  }
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul {
  max-height: 18rem;
  overflow-y: scroll;
}

@supports ((-moz-appearance: none)) {
  .walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul {
    width: -webkit-fill-available;
  }
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul li {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
  border-radius: 9999px;
  justify-content: flex-start;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul li:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(57 57 57 / var(--tw-bg-opacity, 1));
}

.walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul li .itemName {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  text-transform: none;
  -webkit-line-clamp: 1;
  text-wrap: nowrap;
  padding: .5rem 1rem;
  font-size: .9rem;
  line-height: 2;
}

@supports ((-moz-appearance: none)) {
  .walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul li .itemName {
    width: -moz-available;
  }
}

@supports ((-webkit-appearance: none)) {
  .walletTransactionsContainer .dropdownContainer .selectionDropDown .dropdownListWrapper ul li .itemName {
    width: -webkit-fill-available;
  }
}

@container solidPageContainer (width <= 640px) {
  .walletTransactionsContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .walletTransactionsContainer .headerSection {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }

  .walletTransactionsContainer .headerSection .infoSection {
    flex-direction: column;
  }

  .walletTransactionsContainer .transactionItem {
    flex-direction: column;
    gap: 1.5rem;
  }

  @supports ((-moz-appearance: none)) {
    .walletTransactionsContainer .transactionItem .leftWrapper {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .walletTransactionsContainer .transactionItem .leftWrapper {
      width: -webkit-fill-available;
    }
  }

  .walletTransactionsContainer .transactionItem .leftWrapper .firstDiv {
    gap: .5rem;
  }

  .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
    justify-content: space-between;
  }

  @supports ((-moz-appearance: none)) {
    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper {
      width: -webkit-fill-available;
    }
  }

  .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .title {
    font-size: .75rem;
    line-height: 1rem;
  }

  .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistText {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .titleContainer .subTitle {
    max-width: 10rem;
    font-size: .75rem;
    line-height: 1rem;
  }

  .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer {
    justify-content: flex-end;
    gap: .25rem;
  }

  .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .timeCount {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
    font-size: .75rem;
    line-height: 1rem;
    overflow: visible;
    display: block !important;
  }

  .walletTransactionsContainer .transactionItem .leftWrapper .textWrapper .timeArtistContainer .artistImage {
    display: none !important;
  }

  .walletTransactionsContainer .transactionItem .rightWrapper {
    justify-content: space-between;
    gap: .75rem;
  }

  @supports ((-moz-appearance: none)) {
    .walletTransactionsContainer .transactionItem .rightWrapper {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .walletTransactionsContainer .transactionItem .rightWrapper {
      width: -webkit-fill-available;
    }
  }

  .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
    flex-direction: row-reverse;
    gap: 1rem;
  }

  @supports ((-moz-appearance: none)) {
    .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
      width: -moz-available;
    }
  }

  @supports ((-webkit-appearance: none)) {
    .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper {
      width: -webkit-fill-available;
    }
  }

  .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .txIdsWrapper {
    min-width: fit-content;
  }

  .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .timeCount {
    display: none;
  }

  .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper {
    flex-direction: column;
    justify-content: flex-start;
    gap: .25rem;
    min-width: fit-content;
  }

  .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .tokenAmount, .walletTransactionsContainer .transactionItem .rightWrapper .transactionInfoWrapper .amountWrapper .usdAmount {
    font-size: .75rem;
    line-height: 1rem;
  }

  .walletTransactionsContainer .transactionItem .rightWrapper .listenTime {
    min-width: fit-content;
    font-size: .75rem;
    line-height: 1rem;
  }

  .walletTransactionsContainer .transactionItem .rightWrapper .statusIcon {
    display: none;
  }
}

.collapsedPlayerContainer {
  position: absolute;
  bottom: 2rem;
  right: 1rem;
}

.collapsedPlayerContainer .songImageContainer {
  cursor: pointer;
  z-index: 9999;
  border: 1.5px solid #a7a7a7;
  border-radius: 9999px;
  box-shadow: 4px 8px 8px #00000061;
}

.collapsedPlayerContainer .songImageContainer .songImage {
  object-fit: cover;
  border-radius: 9999px;
  width: 4.2rem;
  min-width: 4.2rem;
  height: 4.2rem;
  min-height: 4.2rem;
}

.collapsedPlayerContainer .songImageContainer .songImage.rotate {
  animation: 10s linear infinite collpasedPlayerSongImageRotation;
}

@keyframes collpasedPlayerSongImageRotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.collapsedPlayerContainer .songImageContainer .songImage.pauseRotation {
  animation-play-state: paused;
}

.shareInfoContainer {
  padding: 1rem;
}

.shareInfoContainer .horizontalDivider {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
  height: .05rem;
  margin-left: 0;
  margin-right: 0;
}

.shareInfoContainer .embedTitle {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.shareInfoContainer .embedTitle span {
  --tw-text-opacity: 1;
  color: rgb(18 160 232 / var(--tw-text-opacity, 1));
}

.shareInfoContainer p code {
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity, 1));
  font-size: .75rem;
  line-height: 1rem;
}

.shareInfoContainer p a {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  --tw-text-opacity: 1 !important;
  color: rgb(18 160 232 / var(--tw-text-opacity, 1)) !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.shareInfoContainer .copyIcon {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  margin-left: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.shareInfoContainer .sociaShareWrapper {
  justify-content: center;
  margin-top: 2rem;
  display: flex;
}

.shareInfoContainer .sociaShareWrapper a {
  text-transform: capitalize;
  border-radius: 9999px;
  min-width: 120px;
  margin-left: .5rem;
  margin-right: .5rem;
  font-size: 1.05rem !important;
}

@media (width <= 640px) {
  .shareInfoContainer .sociaShareWrapper a {
    min-width: 95px;
  }
}
/*# sourceMappingURL=index.solid-app.css.map */
