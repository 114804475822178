.bottomPlayerContainer {
    @apply w-full py-4 bg-[#111111] relative;
    @apply flex gap-8 justify-between items-center;
    box-shadow: 0 0 2px #000000;
}

/* Playlist */
.bottomPlayerContainer .playlistDropupSection {
    @apply pl-4 flex justify-start items-center absolute left-0;
}

.bottomPlayerContainer .playlistDropupSection .playlistDropupSectionInner {
    @apply flex justify-start items-center gap-3 cursor-pointer;
}

.bottomPlayerContainer .playlistDropupSection .playlistDropupSectionInner .playlistIcon {
    @apply text-3xl text-white;
}

.bottomPlayerContainer .playlistDropupSection .playlistDropupSectionInner .playlistCounterText {
    @apply text-gray-400 text-base;
}


/* Right section */
.bottomPlayerContainer .playerRightSection {
    @apply absolute right-0 pr-4 flex justify-end items-center gap-12;
}

/* Secondary Controls */
.bottomPlayerContainer .playerRightSection .secondaryControls {
    @apply flex justify-end items-center gap-12;
}

/* Visualizer */

.bottomPlayerContainer .playerRightSection .secondaryControls .visualizerIcon {
    @apply w-[1.4rem] h-[1.4rem] cursor-pointer;
}

/* Shuffle/Repeat Icons - Desktop */
.bottomPlayerContainer .playerRightSection .secondaryControls .shuffleIcon,
.bottomPlayerContainer .playerRightSection .secondaryControls .repeatIcon {
    @apply w-7 h-7 cursor-pointer;
}

/* Wallet */
.bottomPlayerContainer .playerRightSection .walletDropupSection {
    @apply relative;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner {
    @apply flex justify-end items-center gap-3 cursor-pointer;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .walletBalanceWrapper {
    @apply flex flex-col gap-2;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .walletBalanceWrapper .walletBalanceText {
    @apply text-gray-200 text-sm leading-none cursor-pointer;
}

.bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner svg {
    @apply w-8 h-8 !text-white cursor-pointer;
}

.bottomPlayerContainer .playerRightSection .addToPlaylistDropupWrapper {
    @apply relative;
}



/* Smaller screen */

@media (max-width: 1280px) {

    .bottomPlayerContainer .playerRightSection {
        @apply gap-8;
    }

    .bottomPlayerContainer .playerRightSection .secondaryControls {
        @apply gap-8;
    }
}

@media (max-width: 960px) {
    .bottomPlayerContainer .playerRightSection {
        @apply gap-5;
    }

    .bottomPlayerContainer .playerRightSection .secondaryControls {
        @apply gap-5;
    }

    .bottomPlayerContainer .playerRightSection .secondaryControls .shuffleIcon,
    .bottomPlayerContainer .playerRightSection .secondaryControls .repeatIcon {
        @apply w-6 h-6 cursor-pointer;
    } 

    .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner {
        @apply gap-2;
    }
    
    .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .walletBalanceWrapper .walletBalanceText {
        @apply text-base;
    }
    
    .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner svg {
        @apply w-6 h-6;
    }
}


/* Mobile */

@media (max-width: 720px) {
    .bottomPlayerContainer {
        @apply items-end;
    }

    .bottomPlayerContainer .playlistDropupSection {
        @apply bottom-8;
    }

    .bottomPlayerContainer .playlistDropupSection .playlistDropupSectionInner {
        @apply items-end;
    }

    .bottomPlayerContainer .playerRightSection {
        @apply bottom-8;
    }

    .bottomPlayerContainer .playerRightSection .secondaryControls {
        @apply hidden;
    }

    .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .walletBalanceWrapper {
        @apply hidden;
    }

    .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner .spinner {
        @apply hidden;
    }

    .bottomPlayerContainer .playerRightSection .walletDropupSection .walletDropupSectionInner svg {
        @apply w-8 h-8;
    }
}
